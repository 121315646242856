import React, { memo } from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

export const ModalTitleComponent = memo(({ title }: { title: string }) => {
    return (
        <Wrapper>
            <Title variant="h6">{title}</Title>
        </Wrapper>
    )
})

const Wrapper = styled('div')(({ theme }) => ({
    padding: '44px 0 13px 0',
}))

const Title = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '29px',
    padding: '13px 20px',
    flexGrow: 1,
}))
