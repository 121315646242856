import { styled } from '@mui/material/styles'
import ChatPane from '../ChatPane/ChatPane'
import ChatInput from '../ChatInput/ChatInput'
import { actionTypes } from '../../../types/message'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { WebSocketItem } from '../../../api/WebSocket'
import { memo } from 'react'
import { SocketEvent } from '../../../reducers/socketReducer'
import { DotMsgLoader } from '../ChatMessage/components/DotMsgLoader'
import { useGetCurrentThread } from '../../../hooks/useGetCurrentThread'
import { MessagePublicType, MessageTypeEnum } from '../../../types/types'
import { generateUUID } from '../../../utils/utils'
import { useGetCurrentThreadLoadingInput } from '../../../hooks/useGetCurrentThreadLoadingInput'

const ChatWindow = memo(() => {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.auth.user)
  const threadIsLoading = useAppSelector((state) => state.thread.threadIsLoading)
  const currentThreadId = useAppSelector((state) => state.thread.currentThreadId)
  const currentThread = useGetCurrentThread(currentThreadId)
  const currentThreadLoadingInput = useGetCurrentThreadLoadingInput(currentThreadId)

  const handleNewUserMessage = async (text: string) => {
    if (!user) return
    if (!currentThread) return

    localStorage.setItem('stop-openai-response-stream', 'false')

    dispatch({
      type: actionTypes.SET_CURRENT_QUESTION,
      payload: text,
    })
    dispatch({
      type: actionTypes.SET_SOCKET_EVENT,
      payload: SocketEvent.chatCreate,
    })
    dispatch({
      type: actionTypes.SET_CURRENT_THREAD_LOADING_INPUT,
      payload: {
        thread_id: currentThreadId,
        value: true,
      },
    })

    const draftMessageList: MessagePublicType[] = [
      {
        id: generateUUID(),
        text: text,
        type: MessageTypeEnum.question,
        is_legal: false,
        created_at: Date.now(),
      },
      {
        id: generateUUID(),
        text: undefined,
        type: MessageTypeEnum.answer,
        is_legal: false,
        created_at: Date.now() + 1,
      },
    ]

    dispatch({
      type: actionTypes.ADD_QUESTION_LIST_DRAFT,
      payload: {
        thread_id: currentThreadId,
        messages: draftMessageList
      },
    })
  }

  const handleStopResponseStream = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_THREAD_LOADING_INPUT,
      payload: {
        thread_id: currentThreadId,
        value: false,
      },
    })

    dispatch({
      type: actionTypes.SET_SOCKET_EVENT,
      payload: SocketEvent.stopGeneration,
    })
  }

  return (
    <ChatWindowContainer>
      <WebSocketItem />
      {threadIsLoading
        ? <LoaderContainer>
          <DotMsgLoader />
        </LoaderContainer>
        : <>
          <ChatPane />
          <ChatInput
            isLoading={currentThreadLoadingInput}
            handleNewUserMessage={handleNewUserMessage}
            handleStopGeneration={handleStopResponseStream}
          />
        </>
      }
    </ChatWindowContainer>
  )
})

export default ChatWindow

const LoaderContainer = styled('div')({
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const ChatWindowContainer = styled('div')({
  display: 'flex',
  flexGrow: 1,
  width: 'calc(100% - 380px)',
  flexDirection: 'column',
})
