import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { DoneIcon } from '../../../../icons/DoneIcon'

type Props = {
    progress?: number
    text?: string
}

const ProgressBar = (props: Props) => {
    const {
        progress = 0,
        text = 'Loading'
    } = props;

    const [isFullLoading, setIsFullLoading] = useState(false)

    useEffect(() => {
        if (progress === 1) {
            setIsFullLoading(true)
        }
    }, [progress])

    if (isFullLoading) {
        return (
            <PrBar>
                <PrBarFillFull style={{
                    maxWidth: `100%`,
                    backgroundColor: '#5b9b64'
                }}></PrBarFillFull>
                <PrBarText>
                    <DoneIcon/>
                </PrBarText>
            </PrBar>
        )
    }

    return (
        <PrBar>
            <PrBarFill style={{
                maxWidth: `${progress * 100}%`,
                backgroundColor: '#0092a4'
            }}></PrBarFill>
            <PrBarText>
                {text}
            </PrBarText>
        </PrBar>
    );
};

const PrBar = styled('div')({
    width: '100%',
    height: '36px',
    backgroundColor: '#494949',
    overflow: 'hidden',
    position: 'relative'
})

const PrBarFill = styled('div')({
    width: '100%',
    maxWidth: '0%',
    height: '100%',
    transition: 'max-width 2.5s ease-in-out',
})

const PrBarFillFull = styled('div')({
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    transition: 'max-width 2.5s ease-in-out',
})

const PrBarText = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontWeight: '400',
    fontSize: '14px',
    whiteSpace: 'nowrap',
})

export default ProgressBar;
