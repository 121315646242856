import { memo } from 'react'
import s from './style.module.css'

interface DotMsgMiniLoaderProps {
    size?: number
    marginRight?: string
}
export const DotMsgLoader = memo(
    ({ size = 10, marginRight = '14px' }: DotMsgMiniLoaderProps) => {
        return (
            <div className={s.dot_loader}>
                <div style={{ width: size, height: size, marginRight }}></div>
                <div style={{ width: size, height: size, marginRight }}></div>
                <div style={{ width: size, height: size, marginRight }}></div>
                <div style={{ width: size, height: size }}></div>
            </div>
        )
    }
)
