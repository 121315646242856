import LegalAPI, { url } from '../LegalAPI'

interface LoginData {
    email: string
    password: string
    keep_logged_in: boolean
}

export const login = async (data: LoginData) => {
    return await LegalAPI.post(url`/login/`, data)
}

export const refresh = async () => {
    return await LegalAPI.post(url`/refresh/`)
}

export const logout = async () => {
    return await LegalAPI.get(url`/logout/`)
}

export const getUser = async () => {
    return await LegalAPI.get(url`/me/`)
}

export const refreshToken = async () => {
    return await LegalAPI.post(url`/refresh/`, {})
}

export const getRefreshToken = async (): Promise<string | undefined> => {
    try {
        const response = await refreshToken()
        const { token } = response.data
        return token
    } catch (error) {
        console.error('Failed to refresh token', error)
    }
}

interface RegisterCompanyData {
    name: string
    registrationCode: string
    legalAddress: string
    vatCode: string
    email: string
    phone: string
}

interface RegisterData {
    name: string
    email: string
    phone: string
    password: string
    password2: string
    company_account?: boolean
    company_data?: RegisterCompanyData
}

export const register = async (data: RegisterData) => {
    return await LegalAPI.post(url`/users/`, data)
}
