import { ListItem, ListItemText, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { format } from 'date-fns'
import { ThreadListItemType } from '../../../types/thread'
import ThreadTag from '../ThreadTag/ThreadTag'
import { actionTypes } from '../../../types/message'
import { useDispatch } from 'react-redux'
import { memo } from 'react'
import { useAppSelector } from '../../../hooks/storeHooks'

interface ThreadListItemProps {
  thread: ThreadListItemType
}

const ThreadListItem = memo(({
                               thread,
                             }: ThreadListItemProps) => {
  const dispatch = useDispatch()

  const currentThreadId = useAppSelector(state => state.thread.currentThreadId)

  const isCurrentThread = currentThreadId === thread.id

  const formattedDate = format(
    new Date(thread.updated_at),
    'dd.MM.yyyy HH:mm',
  )

  const openChatHandler = () => {
    if (isCurrentThread) return

    dispatch({
      type: actionTypes.SET_CURRENT_THREAD_ID,
      payload: thread.id,
    })
  }

  return (
    <ThreadListItemContainer
      style={{
        backgroundColor: isCurrentThread ? '#4F4C4C' : '',
      }}
      alignItems="flex-start"
      onClick={openChatHandler}
    >
      <ThreadListItemText
        primary={thread.title}
        secondary={formattedDate}
        secondaryTypographyProps={{
          style: { whiteSpace: 'nowrap' },
        }}
      />
      <Stack flexWrap="wrap" direction="row" rowGap={1}>
        {thread.tags.map((tag, index) => (
          <ThreadTag key={index} tag={tag} />
        ))}
      </Stack>
    </ThreadListItemContainer>
  )
})

export default ThreadListItem

const ThreadListItemContainer = styled(ListItem)(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.light,
  '&:hover': {
    backgroundColor: '#4F4C4C',
  },
}))

const ThreadListItemText = styled(ListItemText)(({ theme }) => ({
  '.MuiListItemText-primary': {
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.MuiListItemText-secondary': {
    color: 'rgba(255,255,255,0.5)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
