import { memo } from 'react'

interface IconProps {
    width?: number
    height?: number
    color?: string
}

export const ScaleIcon = memo(
    ({ width = 40, height = 40, color = '#202020' }: IconProps) => {
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M36.9711 21.6725C36.9639 21.6291 36.9567 21.5784 36.9421 21.5278L32.3174 10.9612H30.1028L25.4781 21.5278C25.4637 21.5784 25.4564 21.6291 25.4491 21.6725C25.4419 21.7231 25.4202 21.7666 25.4202 21.81V21.8172C25.4202 24.6108 27.6927 26.8834 30.4863 26.8834H31.9338C34.7274 26.8834 37 24.6108 37 21.8172V21.81C37.0001 21.7666 36.9783 21.7231 36.9711 21.6725H36.9711ZM27.2513 21.0935L31.2101 12.0468L35.169 21.0935H27.2513Z"
                    fill={color}
                />
                <path
                    d="M13.5509 21.6725C13.5437 21.6291 13.5365 21.5784 13.5219 21.5278L8.89724 10.9612H6.68264L2.05796 21.5278C2.04354 21.5784 2.03619 21.6291 2.02898 21.6725C2.02177 21.7231 2 21.7666 2 21.81V21.8172C2 24.6108 4.27257 26.8834 7.06616 26.8834H8.51364C11.3072 26.8834 13.5798 24.6108 13.5798 21.8172V21.81C13.5798 21.7666 13.5582 21.7231 13.551 21.6725H13.5509ZM3.8311 21.0935L7.78995 12.0468L11.7488 21.0935H3.8311Z"
                    fill={color}
                />
                <path
                    d="M37 7.34243V10.2374C37 10.6354 36.6743 10.9611 36.2762 10.9611H21.8015V33.4621C24.5879 33.6286 27.5914 34.1425 27.5914 35.5682C27.5914 37.5802 21.4975 37.7394 19.6303 37.7394C17.7629 37.7394 11.6691 37.5802 11.6691 35.5682C11.6691 34.1425 14.6727 33.6285 17.459 33.4621V10.9611H2.9843C2.58624 10.9611 2.26056 10.6354 2.26056 10.2374V7.34243C2.26056 6.94437 2.58624 6.61869 2.9843 6.61869H17.459V3.72374C17.459 3.32568 17.7847 3 18.1828 3H21.0777C21.4758 3 21.8015 3.32568 21.8015 3.72374V6.61869H36.2762C36.6743 6.61869 37 6.94437 37 7.34243Z"
                    fill={color}
                />
            </svg>
        )
    }
)
