import { styled } from '@mui/system'
import React, { memo } from 'react'
import { IconButton } from '@mui/material'
import CoreIcon from '../../Core/CoreIcon'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useAppDispatch } from '../../../hooks/storeHooks'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../../actions/authActions'
import { toast } from 'react-toastify'
import { logout } from '../../../api/utils/user'

const SettingsPane = memo(() => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleLogout = async () => {
        await logout()
        dispatch(logoutUser())
        handleClose()
        toast.info('Logged out')
        navigate('/login')
    }

    // const handleRegister = () => {
    //     dispatch(logoutUser())
    //     handleClose()
    //     navigate('/signup')
    // }

    return (
        <Container>
            <TopText>Powered by LegalGPT SaaS</TopText>
            <Icons>
                <SpecialIconButton>
                    <CoreIcon iconName="user-icon" color="white" size={40} />
                </SpecialIconButton>
                <SpecialIconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'settings-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <CoreIcon
                        iconName="settings-icon"
                        color="white"
                        size={40}
                    />
                </SpecialIconButton>
            </Icons>

            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleLogout}>Logout.</MenuItem>
            </Menu>
        </Container>
    )
})

export default SettingsPane

const Container = styled('div')(({ theme }) => ({
    width: '270px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '15px',
}))

const TopText = styled('div')(({ theme }) => ({
    fontSize: '16',
    color: 'rgba(255,255,255,0.3)',
}))

const Icons = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '22px',
    color: 'rgba(255,255,255,0.77)',
    padding: '13px',
}))

const SpecialIconButton = styled(IconButton)(({ theme }) => ({
    width: '40px',
    height: '40px',
    opacity: '0.77',
    transition: 'transform 0.2s ease, background-color 0.2s ease',
    '&:hover': {
        transform: 'scale(1.2)',
    },
}))
