import React from 'react'
import { ContextState } from '../types/context'

const defaultState: ContextState = {
    user: null,
    activeThread: null,
    settings: null,
    company: null,
    setUser: () => {},
    setActiveThread: () => {},
    setSettings: () => {},
    setCompany: () => {},
}

export const AppContext = React.createContext<ContextState>(defaultState)
