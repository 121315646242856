import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import LogoBlock from '../../components/LogoBlock'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { logoutUser } from '../../actions/authActions'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { register } from '../../api/utils/user'
import CustomStepper from '../../components/Auxilary/CustomStepper'
import RegisterUser from '../../components/Auth/SignUpComponents/RegisterUser'
import RegisterCompany from '../../components/Auth/SignUpComponents/RegisterCompany'

export interface RegisterFormData {
    name: string
    email: string
    phone: string
    password: string
    password2: string
    createCompany: boolean
}

export interface CompanyRegistrationFormData {
    name: string
    registrationCode: string
    legalAddress: string
    vatCode: string
    email: string
    phone: string
}

const SignupAccountPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const auth = useAppSelector((state) => state.auth)

    const navigate = useNavigate()

    const [step, setStep] = useState<number>(0)
    const [stepsTitles, setStepsTitles] = useState<string[]>(['User details'])

    const [formData, setFormData] = useState<RegisterFormData>({
        name: '',
        email: '',
        phone: '',
        password: '',
        password2: '',
        createCompany: false,
    })

    const [companyFormData, setCompanyFormData] =
        useState<CompanyRegistrationFormData>({
            name: '',
            registrationCode: '',
            legalAddress: '',
            vatCode: '',
            email: '',
            phone: '',
        })

    useEffect(() => {
        if (auth.user.id !== -1) {
            dispatch(logoutUser())
        }
    }, [auth])

    const handleChange = (name: string, value: any) => {
        if (name === 'createCompany') {
            if (value) {
                setStepsTitles(() => ['User details', 'Company details'])
            } else {
                setStepsTitles(['User details'])
            }
        }

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleChangeCompany = (name: string, value: any) => {
        setCompanyFormData((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (formData.password !== formData.password2) {
            toast.error("Passwords don't match")
            return
        }

        try {
            const submitData: any = {
                ...formData,
            }
            submitData.company_account = submitData.createCompany
            delete submitData.createCompany

            if (submitData.company_account) {
                submitData.company_data = {
                    name: companyFormData.name,
                    registration_code: companyFormData.registrationCode,
                    legal_address: companyFormData.legalAddress,
                    vat_code: companyFormData.vatCode,
                    email: companyFormData.email,
                    phone: companyFormData.phone,
                }
            }

            await register(submitData)
            toast.success('User created')
            navigate('/login')
        } catch (error) {
            toast.error('Error registering user')
            console.error('Error registering user: ', error)
        }
    }

    const handleCancel = () => {
        navigate('/login')
    }

    const handleNextStep = () => {
        setStep((prev) => prev + 1)
    }

    const handlePrevStep = () => {
        if (step > 0) {
            setStep((prev) => prev - 1)
        }
    }

    return (
        <MainContainer>
            <LogoBlock />

            <FormContainer onSubmit={handleSubmit}>
                <CustomStepper step={step} steps={stepsTitles} />

                {step === 0 && (
                    <RegisterUser
                        formData={formData}
                        handleChange={handleChange}
                        handleCancel={handleCancel}
                        handleNextStep={handleNextStep}
                    />
                )}
                {step === 1 && (
                    <RegisterCompany
                        companyFormData={companyFormData}
                        handleChangeCompany={handleChangeCompany}
                        handlePrevStep={handlePrevStep}
                    />
                )}
            </FormContainer>
        </MainContainer>
    )
}

export default SignupAccountPage

const MainContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    height: 'calc(100vh - 80px)',
}))

const FormContainer = styled('form')(({ theme }) => ({
    zIndex: 100,
    display: 'flex',
    maxWidth: '1000px',
    width: '1000px',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '30px 185px',
}))
