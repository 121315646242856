import { TopAct, TopActChunk } from '../../types/types'
import { FC, useState } from 'react'
import { styled } from '@mui/material/styles'
import { ItemWrapper } from '../Chat/components/ExpansionModal/components/ItemWrapper'

const parseTitle = (item: TopActChunk): string => {
  const paragraphPart = item.paragraph_variation_no
    ? `${item.paragraph_no}.${item.paragraph_variation_no}`
    : `${item.paragraph_no}`

  const clausePart = item.clause_variation_no
    ? `${item.clause_no}.${item.clause_variation_no}`
    : `${item.clause_no}`

  return `§ ${paragraphPart} LG ${clausePart}`
}

type PropsT = {
  top_act: TopAct
  top_act_chunks?: TopActChunk[]
}

const RelevantActItem: FC<PropsT> = ({
  top_act,
  top_act_chunks = [],
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const uniquePartNumbers: number[] = []
  top_act_chunks.forEach(item => {
    if (uniquePartNumbers.includes(item.part_no)) {
      uniquePartNumbers.push(item.part_no)
    }
  })
  uniquePartNumbers.sort((a, b) => a - b)
  const parts = uniquePartNumbers.join(', ')

  return (
    <ItemWrapper>
      <Header>
        <Abbreviation>{top_act.abbreviation
          ? top_act.abbreviation
          : 'SEADUS'
        }</Abbreviation>
        <Name>{top_act.act_name}</Name>
        <ButtonPart>
          <Hits>{top_act.hits}</Hits>
        </ButtonPart>
        <ExpandButton
          onClick={toggleAccordion}
          className={isOpen ? 'open' : ''}
        />
      </Header>
      {isOpen && (
        <>
          <LinkWrapper>
            Link:{' '}
            <a href={top_act.act_url} target="_blank">
              {top_act.act_url}
            </a>
          </LinkWrapper>

          {parts && <PartsTitle>PARTS {parts}</PartsTitle>}

          {top_act_chunks.map((item, idx) => {
            return (
              <Wrapper key={idx}>
                <Title>
                  {parseTitle(item)}
                </Title>
                <Text>{item.chunk_content}</Text>
              </Wrapper>
            )
          })}
        </>
      )}
    </ItemWrapper>
  )
}

export { RelevantActItem }

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '15px',
}))

const ButtonPart = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
}))

const Abbreviation = styled('span')(({ theme }) => ({
  backgroundColor: '#FFE500',
  borderRadius: '7px',
  padding: '3px 10px',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '22px',
  color: '#000000',
  textAlign: 'center',
}))

const Name = styled('span')(({ theme }) => ({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '22px',
  color: '#000000',
  flexGrow: 1,
}))

const Hits = styled('span')(({ theme }) => ({
  backgroundColor: '#0094a2',
  borderRadius: '50%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',

  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  color: 'white',
}))

const ExpandButton = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: '12px solid transparent',
  borderRight: '12px solid transparent',
  borderTop: '18px solid #000',
  cursor: 'pointer',
  margin: '16px 8px',
  transform: 'rotate(0deg)',
  transition: 'transform 0.3s',

  '&.open': {
    transform: 'rotate(180deg)',
  },
}))

const PartsTitle = styled('span')(({ theme }) => ({
  backgroundColor: '#F3F3F3',
  padding: '5px 0',
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: 400,
  textAlign: 'center',
  textTransform: 'capitalize',
}))

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '10px',
}))

const Title = styled('span')(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 700,
  textTransform: 'capitalize',
}))

const Text = styled('span')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 400,
}))

const LinkWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  lineHeight: '19px',
  height: '44px',
  fontWeight: 400,
  gap: '4px',
}))
