import { TopEuLaw, TopEuLawChunk } from '../../../../types/types'
import { RelevantEuLawItem } from './RelevantEuLawItem'
import { ItemsWrapper } from './components/ItemsWrapper'
import { FC } from 'react'

interface Props {
  top_eu_laws: TopEuLaw[]
  top_eu_law_chunks?: TopEuLawChunk[] | null
}

export const RelevantEuLaws: FC<Props> = ({
  top_eu_laws,
  top_eu_law_chunks = [],
}) => {
  return (
    <ItemsWrapper>
      {top_eu_laws.map((item, idx) => {
        const select_top_eu_law_chunks = (top_eu_law_chunks || []).filter(
          (item_chunk) => item_chunk.eu_law_id === item.eu_law_id,
        )

        return (
          <RelevantEuLawItem
            top_eu_law={item}
            top_eu_law_chunks={select_top_eu_law_chunks}
            key={idx}
          />
        )
      })}
    </ItemsWrapper>
  )
}
