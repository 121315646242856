import axios from 'axios'
import { BACKEND_URL } from '../config'
import store from '../store/store'
import checkTokenExpired from '../helpers/checkTokenExpired'
import { setToken } from '../actions/authActions'
import { getRefreshToken } from './utils/user'

export const url = (path: any): string => `${BACKEND_URL}${path}`

const LegalAPI = axios.create({
    baseURL: BACKEND_URL,
    withCredentials: true,
})

LegalAPI.interceptors.request.use(
    (config) => {
        const state = store.getState()
        const token = state?.auth?.token
        const isExpired = checkTokenExpired(token)
        if (token && !isExpired) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

LegalAPI.interceptors.response.use(
    async (config) => {
        return config;
    },
    async (error) => {
        if (error.response.status === 403) {
            const newToken = await getRefreshToken()
            if (newToken) {
                store.dispatch(setToken(newToken))
            }
        }
    }
)

export default LegalAPI
