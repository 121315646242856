import { memo, useEffect, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import ThreadList from '../../components/Thread/ThreadList/ThreadList'
import ChatWindow from '../../components/Chat/ChatWindow/ChatWindow'
import ThreadListSearch from '../../components/Thread/ThreadListSearch/ThreadListSearch'
import NewThreadBlock from '../../components/Thread/NewThreadBlock/NewThreadBlock'
import SettingsPane from '../../components/Settings/SettingsPane/SettingsPane'
import LogoBlock from '../../components/LogoBlock'
import { Box } from '@mui/material'
import { useAppSelector } from '../../hooks/storeHooks'
import { useNavigate } from 'react-router-dom'
import { ThreadSearchTags } from '../../components/Thread/ThreadSearchTags/ThreadSearchTags'
import { getThreadById, getThreadList } from '../../api/utils/threads'
import { ThreadListFilterQuery } from '../../types/threads'
import { actionTypes } from '../../types/message'
import { useDispatch } from 'react-redux'
import { useGetCurrentThread } from '../../hooks/useGetCurrentThread'
import { NEW_THREAD_ID_SPLASH } from '../../reducers/threadReducer'

const ChatPage = memo(() => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = useAppSelector((state) => state.auth.token)
  const tags: string[] = useAppSelector((state) => state.search.searchTags)
  const searchValue = useAppSelector((state) => state.search.searchValue)
  const searchTags = useAppSelector((state) => state.search.searchTags)
  const currentThreadId = useAppSelector((state) => state.thread.currentThreadId)
  const currentThread = useGetCurrentThread(currentThreadId)

  const loadThreadList = useCallback(async () => {
    if (!token) {
      navigate('/login')
      return
    }
    try {
      dispatch({
        type: actionTypes.CHANGE_THREAD_IS_LOADING,
        payload: true,
      })
      const payload: ThreadListFilterQuery = {
        text: searchValue,
        tags: searchTags,
      }
      const response = await getThreadList(payload)

      const currentThreadIdInLS = localStorage.getItem('currentThreadId')

      dispatch({
        type: actionTypes.SET_THREAD_LIST,
        payload: {
          threads: response.threads,
          currentThreadIdInLS,
        },
      })
    } catch (e) {
      console.log(e)
    }
    dispatch({
      type: actionTypes.CHANGE_THREAD_IS_LOADING,
      payload: false,
    })
  }, [dispatch, navigate, searchValue, searchTags])

  const loadThreadById = useCallback(async () => {
    if (currentThreadId === NEW_THREAD_ID_SPLASH) {
      return
    }

    localStorage.setItem('currentThreadId', currentThreadId)

    try {
      if (!currentThread) {
        dispatch({
          type: actionTypes.CHANGE_THREAD_IS_LOADING,
          payload: true,
        })

        const response = await getThreadById(currentThreadId)
        dispatch({
          type: actionTypes.SET_CURRENT_THREAD,
          payload: response,
        })
      }
    } catch (e) {
      console.log(e)
    }
    dispatch({
      type: actionTypes.CHANGE_THREAD_IS_LOADING,
      payload: false,
    })
  }, [dispatch, currentThreadId])

  useEffect(() => {
    loadThreadList()
  }, [loadThreadList])

  useEffect(() => {
    loadThreadById()
  }, [loadThreadById])

  return (
    <MainContainer>
      <Sidebar>
        <LogoBlock />
        <ThreadListSearch />
        <NewThreadBlock />
        {tags.length > 0 && <ThreadSearchTags tags={tags} />}
        <ThreadListContainer className="custom-scrollbar yellow">
          <ThreadList />
        </ThreadListContainer>
      </Sidebar>
      <Content>
        <SettingsPane />
        <ChatWindow />
      </Content>
    </MainContainer>
  )
})

export default ChatPage

const MainContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: '40px',
  display: 'flex',
  gap: '25px',
  height: 'calc(100vh - 80px)',
}))

const Sidebar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '9px',
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.background.paper,
  flex: 1,
}))

const ThreadListContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  margin: '0 10px 0 0',
}))
