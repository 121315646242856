import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect, useRef } from 'react'
import { CloseIcon } from '../../../../icons/CloseIcon'
import ReactDOM from 'react-dom'
import { QueryExpansion } from './QueryExpansion'
import { RelevantActs } from './RelevantActs'
import { RelevantCourtCases } from './RelevantCourtCases'
import { RelevantEuLaws } from './RelevantEuLaws'
import { ModalTitleComponent } from './ModalTitleComponent'
import { RelevantIntentDocuments } from './RelevantIntentDocuments'
import { DotMsgLoader } from '../../ChatMessage/components/DotMsgLoader'
import { QueryContext } from '../../../../types/types'

export interface ContextModalProps {
  onClose: () => void
  context?: QueryContext
}

const ContextModal = ({ onClose, context }: ContextModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const handleClose = () => {
    onClose()
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target as Node)
    ) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return ReactDOM.createPortal(
    <Modal>
      <Wrapper ref={modalRef} className="custom-scrollbar grey">
        <ModalTitle>
          <span>Response context</span>
          <IconWrapper onClick={handleClose}>
            <CloseIcon width={25} height={25} color="white" />
          </IconWrapper>
        </ModalTitle>
        {context ? (
          <ModalSectionWrapper>
            <ModalSectionHeading variant="h6">
              Query expansion
            </ModalSectionHeading>
            <QueryExpansion context={context} />

            <ModalTitleComponent title="Relevant acts identified" />
            <RelevantActs
              top_acts={context.top_acts}
              top_act_chunks={context.top_act_chunks}
            />

            <ModalTitleComponent title="Relevant court cases identified" />
            <RelevantCourtCases
              top_court_cases={context.top_court_cases}
              top_court_case_chunks={context.top_court_case_chunks}
            />

            <ModalTitleComponent title="Relevant EU laws identified" />
            <RelevantEuLaws
              top_eu_laws={context.top_eu_laws}
              top_eu_law_chunks={context.top_eu_law_chunks}
            />

            <ModalTitleComponent title="Relevant intent documents identified" />
            <RelevantIntentDocuments
              top_intent_documents={context.top_intent_documents}
              top_intent_document_chunks={context.top_intent_document_chunks}
            />
          </ModalSectionWrapper>
        ) : <LoaderContainer>
          <DotMsgLoader />
        </LoaderContainer>}
      </Wrapper>
    </Modal>,
    document.body,
  )
}

export default ContextModal

const Modal = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 100,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  fontFamily: 'Arial, sans-serif',
}))

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.common.white,
  maxHeight: '100vh',
  overflowY: 'auto',
  margin: '0 32px',

  minWidth: '320px',
  width: '96vw',
}))

const IconWrapper = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
}))

const ModalTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '30px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const ModalSectionWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  backgroundColor: theme.palette.background.default,
  padding: '16px 32px',
}))

const ModalSectionHeading = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '29px',
  padding: '13px 20px',
  flexGrow: 1,
}))

const LoaderContainer = styled('div')({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '48px',
})
