import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import FeedbackBlock from '../FeedbackBlock/FeedbackBlock'
import { Grade } from '../../../types/grade'
import { Typography } from '@mui/material'
import { Reference, actionTypes } from '../../../types/message'
import { useMessagesDispatch } from '../../../context/AppContextProvider'
import Markdown from 'markdown-to-jsx'
import { toast } from 'react-toastify'
import ContextModal from '../components/ExpansionModal/ContextModal'
import { CopyIcon } from '../../../icons/CopyIcon'
import { ThreadLoadingStep } from '../../../reducers/threadReducer'
import ProgressBar from './components/ProgressBar'
import { MessagePublicType, MessageTypeEnum } from '../../../types/types'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { getExpandedAnswerById } from '../../../api/utils/expanded_anwers'
import { useGetCurrentQueryContext } from '../../../hooks/useGetCurrentQueryContext'

const getProgress = (step_no?: number, step_total?: number): number => {
  if (!step_no) return 0
  if (!step_total) return 0
  return step_no / step_total
}

interface ChatMessageProps {
  message: MessagePublicType
  currentThreadLoadingStep: ThreadLoadingStep | undefined
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  currentThreadLoadingStep,
}) => {
  const messagesDispatch = useMessagesDispatch()
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.auth.user)
  const context = useGetCurrentQueryContext(message.expanded_answer_id)

  const [showContext, setShowContext] = useState<boolean>(false)
  const [isFavorite, setIsFavorite] = useState<boolean>(false)

  function handleGradeMessage(grade: Grade) {
    messagesDispatch({
      type: actionTypes.GRADE_RESPONSE,
      payload: {
        id: message.id,
        rating: grade,
      },
    })
  }

  const handleCloseExpansion = () => {
    setShowContext(false)
  }

  const handleAddToFavorites = () => {
    const newFavorite = !isFavorite
    setIsFavorite(newFavorite)
    if (newFavorite) {
      toast.success('Added to favorites')
    } else {
      toast.info('Removed from favorites')
    }
  }

  const ReferenceTag = ({ tag, link }: Reference) => {
    return (
      <ReferenceSpan>
        <a href={link} target="blank">
          {tag}
        </a>
      </ReferenceSpan>
    )
  }

  const processText = (inputText: string) => {
    // Adjusted regex pattern to capture tag and link
    const tagPattern = /\{\s*"tag"\s*:\s*"([^"]+)"\s*,\s*"link"\s*:\s*"([^"]+)"\s*\}/g
    const stringWithLinks = inputText.replace(
      tagPattern,
      (match, tag, link) => {
        return `[${tag.replace(/ /g, '\u00A0')}](${link})`
      },
    )

    const MarkdownOptions = {
      overrides: {
        a: {
          component: (props: {
            href: string
            children: string[]
          }) => (
            <ReferenceTag
              link={props.href}
              tag={props.children[0]}
            />
          ),
        },
      },
    }

    return <Markdown options={MarkdownOptions}>{stringWithLinks}</Markdown>
  }

  const copyHandler = () => {
    if (message.text === undefined) return
    navigator.clipboard
      .writeText(message.text)
      .then(() => toast.success('Copied'))
      .catch((err) => toast.info('Fail to copy'))
  }

  const loadQueryContext = async () => {
    setShowContext(true)
    if (context) return
    if (!message.expanded_answer_id) return
    try {
      const response = await getExpandedAnswerById(message.expanded_answer_id)
      dispatch({
        type: actionTypes.SET_CURRENT_QUERY_CONTEXT,
        payload: response,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      {message.type === MessageTypeEnum.question ? (
        <MessageWrapper>
          <StyledAvatar>
            {user.name[0].toUpperCase()}
          </StyledAvatar>
          <MessageContainer>
            <UserName>{user.name}</UserName>
            <UserMessage>
              <Typography typography="body1">
                {message.text}
              </Typography>
            </UserMessage>
          </MessageContainer>
        </MessageWrapper>
      ) : (
        <MessageWrapper>
          <StyledAvatar>
            <AvatarImage src="/images/legalgpt-profile-image-minimalist.webp" />
          </StyledAvatar>
          <MessageContainer>
            <UserName>LextalGPT</UserName>

            {message.text !== undefined ? (
              message.text.trim() ? (
                <>
                  <BotMessage>
                    <CopyIconWrapper onClick={copyHandler}>
                      <CopyIcon />
                    </CopyIconWrapper>
                    <Typography typography="body1">
                      {processText(message.text)}
                    </Typography>
                  </BotMessage>
                  <FeedbackBlock
                    isFavorite={isFavorite}
                    onGradeSelected={handleGradeMessage}
                    onGetContext={loadQueryContext}
                    onAddToFavorites={handleAddToFavorites}
                    message={message}
                  />
                </>
              ) : null
            ) : (
              <ProgressBar
                progress={getProgress(
                  currentThreadLoadingStep?.progress,
                  currentThreadLoadingStep?.total,
                )}
                text={currentThreadLoadingStep?.label}
              />
            )}

          </MessageContainer>
        </MessageWrapper>
      )}
      {showContext && <ContextModal
        context={context}
        onClose={handleCloseExpansion}
      />}
    </div>
  )
}

export default ChatMessage

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
}))

const MessageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
  width: '100%',
}))

const UserMessage = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '5px',
  whiteSpace: 'pre-line',
  backgroundColor: theme.palette.secondary.main,
  color: 'rgba(255,255,255,0.77)',
}))

const BotMessage = styled('div')(({ theme }) => ({
  whiteSpace: 'pre-line',
  padding: '18px 30px 18px 16px',
  borderRadius: '5px',
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  position: 'relative',
  paddingRight: '30px',
}))

const StyledAvatar = styled('div')(({ theme }) => ({
  backgroundColor: '#aaaaaa',
  overflow: 'hidden',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  flexGrow: 0,
  flexBasis: '50px',
  fontSize: '28px',
  fontWeight: 500,
}))

const AvatarImage = styled('img')({
  height: '100%',
  width: 'auto',
})

const UserName = styled('div')({
  fontSize: '18',
  color: 'rgba(255,255,255,0.5)',
})

const ReferenceSpan = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: '2px 10px',
  borderRadius: '7px',
  margin: '0 3px',
  transition: 'transform 0.3s ease, background-color 0.3s ease',
  '&:hover': {
    transform: 'scale(1.5)',
  },
  '& a': {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontSize: '14px',
  },
}))

const CopyIconWrapper = styled('span')(() => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  '&:hover': {
    opacity: 0.5,
  },
  cursor: 'pointer',
}))
