import React, { memo } from 'react'
import { CloseIcon } from '../../icons/CloseIcon'
import { styled } from '@mui/material/styles'

interface ActListProps {
    acts: string[]
    removeActHandler: (option: string) => void
}
export const ActList = memo(({ acts, removeActHandler }: ActListProps) => {
    const removeHandler = (act: string) => {
        removeActHandler(act)
    }

    return (
        <ActsList>
            {acts.map((act) => (
                <Act key={act}>
                    <span>{act}</span>
                    <IconWrapper onClick={() => removeHandler(act)}>
                        <CloseIcon color="#FFFFFF" />
                    </IconWrapper>
                </Act>
            ))}
        </ActsList>
    )
})

const ActsList = styled('div')(({ theme }) => ({
    paddingTop: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
}))

const Act = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: theme.palette.secondary.light,
    gap: '7px',
    borderRadius: '7px',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
}))

const IconWrapper = styled('span')(({ theme }) => ({
    display: 'flex',
    cursor: 'pointer',
}))
