import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { ButtonRow, FormRow, InputLabel } from './StyledWidgets'
import CoreInput, { CoreInputType } from '../../Core/CoreInput'
import { RegisterFormData } from '../../../pages/Auth/SignupAccountPage'
import CoreSwitch from '../../Core/CoreSwitch'
import CoreButton from '../../Core/CoreButton'

interface RegisterUserProps {
    formData: RegisterFormData
    handleChange: (fieldName: string, fieldValue: any) => void
    handleCancel: () => void
    handleNextStep: () => void
}

const RegisterUser: React.FC<RegisterUserProps> = ({
    formData,
    handleChange,
    handleCancel,
    handleNextStep,
}) => {
    const theme = useTheme()

    return (
        <React.Fragment>
            <FormRow>
                <Typography typography="body1" fontSize={26}>
                    Your user account
                </Typography>
            </FormRow>
            <FormRow>
                <InputLabel typography="body1">Your full name</InputLabel>
                <CoreInput
                    value={formData?.name || ''}
                    placeholder="Enter your name"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Text}
                    color={theme.palette.common.black}
                    onChange={(value: string) => handleChange('name', value)}
                    required
                />
            </FormRow>
            <FormRow>
                <InputLabel typography="body1">Email</InputLabel>
                <CoreInput
                    value={formData?.email || ''}
                    placeholder="Enter your email"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Email}
                    color={theme.palette.common.black}
                    onChange={(value: string) => handleChange('email', value)}
                    required
                />
            </FormRow>
            <FormRow>
                <InputLabel typography="body1">Phone number</InputLabel>
                <CoreInput
                    value={formData?.phone || ''}
                    placeholder="Enter phone number"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Text}
                    color={theme.palette.common.black}
                    onChange={(value: string) => handleChange('phone', value)}
                    required
                />
            </FormRow>
            <FormRow>
                <InputLabel typography="body1">Password</InputLabel>
                <CoreInput
                    value={formData?.password || ''}
                    placeholder="Enter password"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Password}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChange('password', value)
                    }
                    required
                />
            </FormRow>
            <FormRow>
                <InputLabel typography="body1">Password repeat</InputLabel>
                <CoreInput
                    value={formData?.password2 || ''}
                    placeholder="Confirm password"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Password}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChange('password2', value)
                    }
                    required
                />
            </FormRow>
            <FormRow>
                <InputLabel typography="body1">
                    I wish to create account for my company
                </InputLabel>
                <CoreSwitch
                    value={formData.createCompany}
                    color={theme.palette.common.black}
                    onChange={(value: boolean) =>
                        handleChange('createCompany', value)
                    }
                />
            </FormRow>

            <ButtonRow>
                <CoreButton
                    text="Cancel"
                    color={theme.palette.background.default}
                    width={200}
                    textColor={theme.palette.common.white}
                    onClick={handleCancel}
                    type="button"
                />
                {formData?.createCompany ? (
                    <CoreButton
                        text="Continue"
                        color={theme.palette.secondary.light}
                        width={200}
                        textColor={theme.palette.common.white}
                        onClick={handleNextStep}
                        type="button"
                    />
                ) : (
                    <CoreButton
                        text="Create account"
                        color={theme.palette.warning.main}
                        textColor={theme.palette.common.black}
                        width={200}
                        onClick={() => {}}
                        type="submit"
                    />
                )}
            </ButtonRow>
        </React.Fragment>
    )
}

export default RegisterUser
