import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { useNavigate } from 'react-router-dom'
import checkTokenExpired from '../../helpers/checkTokenExpired'
import { logoutUser } from '../../actions/authActions'

const ContentWrapper: FC<React.PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch()
    const auth = useAppSelector((state) => state.auth)
    const navigate = useNavigate()

    const checkToken = async () => {
        try {
            const accessToken = auth.token
            if (accessToken) {
                const isExpired = checkTokenExpired(accessToken)
                if (isExpired) {
                    throw Error()
                }
            } else {
               throw Error()
            }
        } catch (e) {
            dispatch(logoutUser())
            navigate('/login')
        }
    }

    useEffect(() => {
        checkToken()
    }, [auth?.token])

    return <React.Fragment>{children}</React.Fragment>
}

export default ContentWrapper
