import { Action } from '@reduxjs/toolkit'
import { actionTypes } from '../types/message'
import { LOGOUT_USER } from '../actions/types'
import { QueryContext, ThreadMainType, ThreadMessageType } from '../types/types'

export interface ThreadLoadingStep {
  thread_id: string
  progress: number
  label: string
  total: number
}

interface InitialState {
  isNewThread: boolean
  threadIsLoading: boolean
  currentThreadId: string
  threadList: ThreadMainType[]
  threadIdList: string[]
  currentThreadList: Record<string, ThreadMessageType>
  currentThreadLoadingStep: Record<string, ThreadLoadingStep | undefined>
  currentQueryContextList: Record<string, QueryContext>
  currentThreadLoadingInput: Record<string, boolean | undefined>
  currentThreadInputText: Record<string, string | undefined>
}

export const NEW_THREAD_ID_SPLASH = 'new_thread'

const initThread: ThreadMessageType = {
  id: NEW_THREAD_ID_SPLASH,
  title: 'New thread',
  created_at: new Date(Date.now()).toUTCString(),
  updated_at: new Date(Date.now()).toUTCString(),
  tags: [],
  messages: [],
}

const INITIAL_STATE: InitialState = {
  isNewThread: true,
  threadIsLoading: false,
  currentThreadId: NEW_THREAD_ID_SPLASH,
  threadList: [],
  threadIdList: [],
  currentThreadList: { [NEW_THREAD_ID_SPLASH]: initThread },
  currentThreadLoadingStep: {},
  currentQueryContextList: {},
  currentThreadLoadingInput: {},
  currentThreadInputText: {},
}

interface ActionType extends Action {
  payload: any
}

export const threadReducer = (state = INITIAL_STATE, action: ActionType) => {
  const newState = { ...state }

  switch (action.type) {
    case actionTypes.SET_CURRENT_THREAD: {
      newState.currentThreadList = {
        ...newState.currentThreadList,
        [action.payload.id]: action.payload,
      }

      break
    }

    case actionTypes.UPDATE_THREAD_IN_LIST: {
      newState.threadList = newState.threadList.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            title: action.payload.title,
            updated_at: action.payload.updated_at,
          }
        }
        return item
      })

      break
    }

    case actionTypes.SET_CURRENT_THREAD_ID: {
      newState.currentThreadId = action.payload
      if (action.payload === NEW_THREAD_ID_SPLASH) {
        newState.isNewThread = true
      } else {
        newState.isNewThread = false

        if (newState.currentThreadInputText[NEW_THREAD_ID_SPLASH]) {
          break;
        }

        if (newState.currentThreadLoadingInput[NEW_THREAD_ID_SPLASH]) {
          break;
        }

        if (newState.currentThreadLoadingStep[NEW_THREAD_ID_SPLASH]) {
          break
        }

        const tempCurrentThreadList = {
          ...newState.currentThreadList,
        }
        delete tempCurrentThreadList[NEW_THREAD_ID_SPLASH]
        newState.currentThreadList = tempCurrentThreadList;

        newState.threadList = newState.threadList.filter(item => item.id !== NEW_THREAD_ID_SPLASH)
        newState.threadIdList = newState.threadIdList.filter(item => item !== NEW_THREAD_ID_SPLASH)

        newState.currentThreadLoadingStep = {
          ...newState.currentThreadLoadingStep,
          [NEW_THREAD_ID_SPLASH]: undefined,
        }
        newState.currentThreadLoadingInput = {
          ...newState.currentThreadLoadingInput,
          [NEW_THREAD_ID_SPLASH]: undefined,
        }
        newState.currentThreadInputText = {
          ...newState.currentThreadInputText,
          [NEW_THREAD_ID_SPLASH]: '',
        }
      }
      break
    }

    case actionTypes.UPDATE_NEW_THREAD_INFO: {
      const newThread: ThreadMessageType = {
        ...action.payload,
        messages: newState.currentThreadList[NEW_THREAD_ID_SPLASH].messages
      }
      const newCurrentThreadList = {
        ...newState.currentThreadList,
        [newThread.id]: newThread
      }
      delete newCurrentThreadList[NEW_THREAD_ID_SPLASH]
      newState.currentThreadList = {
        ...newCurrentThreadList
      }

      const newThreadList = newState.threadList.filter(item => item.id !== NEW_THREAD_ID_SPLASH)
      const newThreadIdList = newState.threadIdList.filter(item => item !== NEW_THREAD_ID_SPLASH)
      newState.threadList = [
        newThread,
        ...newThreadList,
      ]
      newState.threadIdList = [
        newThread.id,
        ...newThreadIdList,
      ]

      if (newState.currentThreadId === NEW_THREAD_ID_SPLASH) {
        newState.isNewThread = false
        newState.currentThreadId = newThread.id
      }

      newState.currentThreadLoadingStep = {
        ...newState.currentThreadLoadingStep,
        [newThread.id]: newState.currentThreadLoadingStep[NEW_THREAD_ID_SPLASH],
        [NEW_THREAD_ID_SPLASH]: undefined,
      }
      newState.currentThreadLoadingInput = {
        ...newState.currentThreadLoadingInput,
        [newThread.id]: true,
        [NEW_THREAD_ID_SPLASH]: undefined,
      }
      newState.currentThreadInputText = {
        ...newState.currentThreadInputText,
        [newThread.id]: '',
        [NEW_THREAD_ID_SPLASH]: '',
      }

      break
    }

    case actionTypes.ADD_NEW_THREAD: {
      newState.isNewThread = true
      newState.threadIsLoading = false

      newState.currentThreadId = NEW_THREAD_ID_SPLASH

      if (newState.threadIdList.includes(NEW_THREAD_ID_SPLASH)) {
        break;
      }

      const newThread = {
        id: NEW_THREAD_ID_SPLASH,
        title: 'New thread',
        created_at: new Date(Date.now()).toUTCString(),
        updated_at: new Date(Date.now()).toUTCString(),
        tags: [],
        messages: [],
      }
      newState.currentThreadList = {
        ...newState.currentThreadList,
        [NEW_THREAD_ID_SPLASH]: newThread,
      }

      newState.threadList = [
        newThread,
        ...newState.threadList
      ]
      newState.threadIdList = [
        NEW_THREAD_ID_SPLASH,
        ...newState.threadIdList
      ]

      newState.currentThreadLoadingStep = {
        ...newState.currentThreadLoadingStep,
        [NEW_THREAD_ID_SPLASH]: undefined,
      }
      newState.currentThreadLoadingInput = {
        ...newState.currentThreadLoadingInput,
        [NEW_THREAD_ID_SPLASH]: undefined,
      }
      newState.currentThreadInputText = {
        ...newState.currentThreadInputText,
        [NEW_THREAD_ID_SPLASH]: '',
      }
      break
    }

    case actionTypes.SET_THREAD_LIST: {
      newState.threadList = action.payload.threads
      newState.threadIdList = newState.threadList.map((item) => item.id)

      if (newState.threadIdList.includes(action.payload.currentThreadIdInLS)) {
        newState.isNewThread = false
        newState.threadIsLoading = false

        newState.currentThreadId = action.payload.currentThreadIdInLS
      } else {
        newState.isNewThread = true
        newState.threadIsLoading = false

        newState.currentThreadId = NEW_THREAD_ID_SPLASH

        const newThread = {
          id: NEW_THREAD_ID_SPLASH,
          title: 'New thread',
          created_at: new Date(Date.now()).toUTCString(),
          updated_at: new Date(Date.now()).toUTCString(),
          tags: [],
          messages: [],
        }
        newState.currentThreadList = {
          ...newState.currentThreadList,
          [NEW_THREAD_ID_SPLASH]: newThread,
        }

        newState.threadList = [
          newThread,
          ...newState.threadList
        ]
        newState.threadIdList = [
          NEW_THREAD_ID_SPLASH,
          ...newState.threadIdList
        ]

        newState.currentThreadLoadingStep = {
          ...newState.currentThreadLoadingStep,
          [NEW_THREAD_ID_SPLASH]: undefined,
        }
        newState.currentThreadLoadingInput = {
          ...newState.currentThreadLoadingInput,
          [NEW_THREAD_ID_SPLASH]: undefined,
        }
        newState.currentThreadInputText = {
          ...newState.currentThreadInputText,
          [NEW_THREAD_ID_SPLASH]: '',
        }
      }
      break
    }

    case actionTypes.ADD_QUESTION_LIST_DRAFT: {
      newState.currentThreadList = {
        ...newState.currentThreadList,
        [action.payload.thread_id]: {
          ...newState.currentThreadList[action.payload.thread_id],
          messages: [
            ...newState.currentThreadList[newState.currentThreadId].messages,
            ...action.payload.messages,
          ],
        },
      }
      break
    }

    case actionTypes.UPDATE_BOT_ANSWER: {
      const lastMessageIdx = newState.currentThreadList[action.payload.thread_id].messages.length - 1
      const messages = newState.currentThreadList[action.payload.thread_id].messages.map((item, idx) => {
        if (idx === lastMessageIdx) {
          return {
            ...item,
            text: item.text
              ? item.text + action.payload.answer
              : action.payload.answer,
          }
        }
        return item
      })
      newState.currentThreadList = {
        ...newState.currentThreadList,
        [action.payload.thread_id]: {
          ...newState.currentThreadList[action.payload.thread_id],
          messages: messages,
        },
      }
      break
    }

    case actionTypes.UPDATE_MESSAGE_METADATA: {
      const lastMessageIdx = newState.currentThreadList[action.payload.thread_id].messages.length - 1
      const messages = newState.currentThreadList[action.payload.thread_id].messages.map((item, idx) => {
        if (idx === lastMessageIdx) {
          return {
            ...item,
            is_legal: action.payload.is_legal,
            expanded_answer_id: action.payload.expanded_answer_id,
          }
        }
        return item
      })
      newState.currentThreadList = {
        ...newState.currentThreadList,
        [action.payload.thread_id]: {
          ...newState.currentThreadList[action.payload.thread_id],
          messages: messages,
        },
      }
      break
    }

    case actionTypes.STOP_GENERATE_ANSWER: {
      const thread_id = action.payload === -1 ? NEW_THREAD_ID_SPLASH : action.payload

      const lastMessageIdx = newState.currentThreadList[thread_id].messages.length - 1
      const messages = newState.currentThreadList[thread_id].messages.filter((item, idx) => idx !== lastMessageIdx)

      newState.currentThreadList = {
        ...newState.currentThreadList,
        [thread_id]: {
          ...newState.currentThreadList[thread_id],
          messages: messages,
        },
      }
      break
    }

    case actionTypes.CHANGE_CHAT_RESPONSE_STATUS: {
      newState.currentThreadLoadingStep = {
        ...newState.currentThreadLoadingStep,
        [action.payload.thread_id]: action.payload,
      }
      break
    }

    case actionTypes.REMOVE_CHAT_RESPONSE_STATUS: {
      newState.currentThreadLoadingStep = {
        ...newState.currentThreadLoadingStep,
        [action.payload.thread_id]: undefined,
      }
      break
    }

    case actionTypes.SET_CURRENT_QUERY_CONTEXT: {
      newState.currentQueryContextList = {
        ...newState.currentQueryContextList,
        [action.payload.id]: action.payload,
      }
      break
    }

    case actionTypes.CHANGE_THREAD_IS_LOADING: {
      newState.threadIsLoading = action.payload
      break
    }

    case actionTypes.SET_CURRENT_THREAD_LOADING_INPUT: {
      newState.currentThreadLoadingInput = {
        ...newState.currentThreadLoadingInput,
        [action.payload.thread_id]: action.payload.value,
      }
      break
    }

    case actionTypes.SET_CURRENT_THREAD_INPUT_TEXT: {
      newState.currentThreadInputText = {
        ...newState.currentThreadInputText,
        [action.payload.thread_id]: action.payload.value,
      }
      break
    }

    case LOGOUT_USER: {
      return INITIAL_STATE
    }

    default:
      break
  }
  return newState
}
