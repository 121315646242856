import { Action } from '@reduxjs/toolkit'
import { actionTypes } from '../types/message'
import { LOGOUT_USER } from '../actions/types'

interface InitialState {
    question: string
    answer: string
    isGenerateAnswer: boolean
}

const INITIAL_STATE: InitialState = {
    question: '',
    answer: '',
    isGenerateAnswer: false,
}
interface ActionType extends Action {
    payload: any
}

export const messageReducer = (state = INITIAL_STATE, action: ActionType) => {
    const newState = { ...state }
    switch (action.type) {
        case actionTypes.SET_CURRENT_QUESTION:
            newState.question = action.payload
            break
        case actionTypes.SET_CURRENT_ANSWER:
            newState.answer = action.payload
            break
        case actionTypes.SET_IS_GENERATE_CURRENT_ANSWER:
            newState.isGenerateAnswer = action.payload
            break
        case LOGOUT_USER:
            return INITIAL_STATE
        default:
            break
    }
    return newState
}
