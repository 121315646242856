import { Chip } from '@mui/material'
import { styled } from '@mui/system'
import { actionTypes } from '../../../types/message'
import { useDispatch } from 'react-redux'
import { SocketEvent } from '../../../reducers/socketReducer'

interface ThreadTagProps {
    tag: string
    highlighted?: boolean
}

const ThreadTag = ({ tag, highlighted }: ThreadTagProps) => {
    const dispatch = useDispatch()

    const addTagToSearchHandler = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation()
        dispatch({ type: actionTypes.ADD_SEARCH_TAG, payload: tag })
        dispatch({
            type: actionTypes.SET_SOCKET_EVENT,
            payload: SocketEvent.threadsListSearch,
        })
    }

    return (
        <ThreadTagContainer
            label={tag}
            tag={tag}
            onClick={addTagToSearchHandler}
            highlighted={highlighted ? true : undefined}
        />
    )
}

export default ThreadTag

const ThreadTagContainer = styled(Chip, {
    shouldForwardProp: (prop) => prop !== 'highlighted',
})<ThreadTagProps>(({ theme, highlighted }) => ({
    marginRight: theme.spacing(0.5),
    zIndex: 100,
    borderRadius: '10px',
    '& .MuiChip-label': {
        color: theme.palette.common.white,
        padding: 0,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        textTransform: 'uppercase',
        fontSize: '12px',
    },
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.secondary.light,
        cursor: '',
    },
    ...(highlighted && {
        backgroundColor: theme.palette.secondary.light,
        color: 'rgba(255,255,255,1)',
    }),
}))
