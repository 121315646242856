import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import CoreInput, { CoreInputType } from '../../components/Core/CoreInput'
import { Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import CoreSwitch from '../../components/Core/CoreSwitch'
import LogoBlock from '../../components/LogoBlock'
import CoreButton from '../../components/Core/CoreButton'
import { getUser, login } from '../../api/utils/user'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { logoutUser, setToken, setUser } from '../../actions/authActions'

interface LoginFormData {
    email: string
    password: string
    rememberPassword: boolean
}

const LoginPage: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const auth = useAppSelector((state) => state.auth)

    const [formData, setFormData] = useState<LoginFormData>({
        email: '',
        password: '',
        rememberPassword: false,
    })
    const [loading, setLoading] = useState<boolean>(false)
    const theme = useTheme()

    const fetchUser = async () => {
        try {
            setLoading(true)
            const res = await getUser()
            dispatch(setUser(res.data))
            setLoading(false)
            toast.success('Logged in successfully!')
            navigate('/chat')
        } catch (error) {
            setLoading(false)
            toast.error('Error fetching user')
            console.error('error fetching user: ', error)
        }
    }

    useEffect(() => {
        if (auth.token) {
            fetchUser()
        }
    }, [auth.token])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!formData.email) {
            toast.error('Email field is empty')
            return
        }
        if (!formData.password) {
            toast.error('Password field is empty')
            return
        }

        try {
            setLoading(true)

            const res: AxiosResponse = await login({
                email: formData.email,
                password: formData.password,
                keep_logged_in: formData.rememberPassword,
            })
            const token = res.data.access_token.token

            dispatch(setToken(token))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Error logging in')
            console.error('error logging in: ', error)
        }
    }

    const handleCreateAccount = () => {
        dispatch(logoutUser())
        navigate('/signup')
    }

    return (
        <MainContainer>
            <LogoBlock />
            <FormContainer onSubmit={handleSubmit}>
                <FormRow>
                    <Typography typography="body1" fontSize={26}>
                        Login to your account
                    </Typography>
                </FormRow>
                <FormRow>
                    <InputLabel typography="body1">Email</InputLabel>
                    <CoreInput
                        value={formData.email}
                        placeholder="Enter your email"
                        backgroundColor={theme.palette.common.white}
                        type={CoreInputType.Email}
                        color={theme.palette.common.black}
                        onChange={(value: string) => {
                            setFormData({ ...formData, email: value })
                        }}
                        disabled={loading}
                        required
                    />
                </FormRow>
                <FormRow>
                    <InputLabel typography="body1">Password</InputLabel>
                    <CoreInput
                        value={formData.password}
                        placeholder="Enter password"
                        backgroundColor={theme.palette.common.white}
                        type={CoreInputType.Password}
                        color={theme.palette.common.black}
                        onChange={(value: string) => {
                            setFormData({ ...formData, password: value })
                        }}
                        disabled={loading}
                        required
                    />
                </FormRow>
                <FormRow>
                    <InputLabel typography="body1">
                        Keep me logged in
                    </InputLabel>
                    <CoreSwitch
                        value={formData.rememberPassword}
                        checked={formData.rememberPassword}
                        color={theme.palette.common.black}
                        onChange={(value: boolean) => {
                            setFormData({
                                ...formData,
                                rememberPassword: value,
                            })
                        }}
                        disabled={loading}
                    />
                </FormRow>
                <ButtonRow>
                    <CoreButton
                        text="Create Account"
                        color={theme.palette.warning.main}
                        textColor={theme.palette.common.black}
                        width={200}
                        onClick={handleCreateAccount}
                        disabled={loading}
                    />
                    <CoreButton
                        text="Forgot Password"
                        color={theme.palette.background.default}
                        width={200}
                        textColor={theme.palette.common.white}
                        onClick={() => {}}
                        disabled={loading}
                    />
                    <CoreButton
                        text="Login"
                        color={theme.palette.secondary.light}
                        width={200}
                        textColor={theme.palette.common.white}
                        onClick={() => {}}
                        type="submit"
                        disabled={loading}
                    />
                </ButtonRow>
            </FormContainer>
        </MainContainer>
    )
}

export default LoginPage

const MainContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.common.white,
    height: 'calc(100vh - 80px)',
}))

const InputLabel = styled(Typography)(({ theme }) => ({
    width: '200px',
    fontSize: '18px',
    textWrap: 'wrap',
}))

const FormContainer = styled('form')(({ theme }) => ({
    zIndex: 100,
    display: 'flex',
    maxWidth: '1000px',
    width: '1000px',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '30px 185px',
}))

const FormRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '35px',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    alignContent: 'flex-start',
    height: '70px',
}))

const ButtonRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px',
    alignContent: 'flex-end',
    height: '70px',
}))
