import { Grade } from './grade'
import { User } from './user'
import { QueryContext } from './types'

export interface Message {
  text?: string
  user?: User
  created_at: string
  rating?: Grade
  id: number
  context?: QueryContext
  is_legal?: boolean
}

export interface Reference {
  tag: string
  link: string
}

export enum actionTypes {
  SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION',
  SET_CURRENT_ANSWER = 'SET_CURRENT_ANSWER',
  ADD_BOT_ANSWER = 'ADD_BOT_ANSWER',
  UPDATE_BOT_ANSWER = 'UPDATE_BOT_ANSWER',
  UPDATE_MESSAGE_EXPANSION = 'UPDATE_MESSAGE_EXPANSION',
  ADD_USER_QUESTION = 'ADD_USER_QUESTION',
  ADD_QUESTION_LIST_DRAFT = 'ADD_QUESTION_LIST_DRAFT',
  SET_IS_GENERATE_CURRENT_ANSWER = 'SET_IS_GENERATE_CURRENT_ANSWER',
  ADD_MESSAGE = 'ADD_MESSAGE',
  UPDATE_MESSAGE = 'UPDATE_MESSAGE',
  SET_EXPANSION = 'SET_EXPANSION',
  COMPLETE_STREAM = 'COMPLETE_STREAM',
  GRADE_RESPONSE = 'GRADE_RESPONSE',
  SET_CURRENT_THREAD = 'SET_CURRENT_THREAD',
  UPDATE_THREAD_IN_LIST = 'UPDATE_THREAD_IN_LIST',
  SET_CURRENT_THREAD_ID = 'SET_CURRENT_THREAD_ID',
  UPDATE_NEW_THREAD_INFO = 'UPDATE_NEW_THREAD_INFO',
  SET_THREAD_LIST = 'SET_THREAD_LIST',
  ADD_NEW_THREAD = 'ADD_NEW_THREAD',
  ADD_CURRENT_THREAD_TO_LIST = 'ADD_NEW_THREAD',
  SET_MESSAGE_EXPANSION = 'SET_MESSAGE_EXPANSION',
  REMOVE_THREAD_LIST = 'REMOVE_THREAD_LIST',
  SET_SOCKET = 'SET_SOCKET',
  CLEAR_SOCKET = 'CLEAR_SOCKET',
  SET_IS_SOCKET_CONNECTED = 'SET_IS_SOCKET_CONNECTED',
  SET_SOCKET_EVENT = 'SET_SOCKET_EVENT',
  SET_SEARCH_VALUE = 'SET_SEARCH_VALUE',
  ADD_SEARCH_TAG = 'ADD_SEARCH_TAG',
  REMOVE_SEARCH_TAG = 'REMOVE_SEARCH_TAG',
  ADD_ACTS = 'ADD_ACTS',
  REMOVE_ACTS = 'REMOVE_ACTS',
  CLEAR_ACTS = 'CLEAR_ACTS',
  SET_PREFERENCE_ACCOUNT_CONTEXT = 'SET_PREFERENCE_ACCOUNT_CONTEXT',
  SET_PREFERENCE_COMPANY_CONTEXT = 'SET_PREFERENCE_COMPANY_CONTEXT',
  SET_PREFERENCE_COLOR = 'SET_PREFERENCE_COLOR',
  CHANGE_THREAD_IS_LOADING = 'CHANGE_THREAD_IS_LOADING',
  ADD_THREAD_TO_CURRENT_LIST = 'ADD_THREAD_TO_CURRENT_LIST',
  CHANGE_CHAT_RESPONSE_STATUS = 'CHANGE_CHAT_RESPONSE_STATUS',
  REMOVE_CHAT_RESPONSE_STATUS = 'REMOVE_CHAT_RESPONSE_STATUS',
  SET_CURRENT_QUERY_CONTEXT = 'SET_CURRENT_QUERY_CONTEXT',
  UPDATE_MESSAGE_METADATA = 'UPDATE_MESSAGE_METADATA',
  SET_CURRENT_THREAD_LOADING_INPUT = 'SET_CURRENT_THREAD_LOADING_INPUT',
  SET_CURRENT_THREAD_INPUT_TEXT = 'SET_CURRENT_THREAD_INPUT_TEXT',
  STOP_GENERATE_ANSWER = 'STOP_GENERATE_ANSWER',
}

export interface AddMessageAction {
  type: actionTypes.ADD_MESSAGE
  payload: Message[]
}

export interface UpdateMessageAction {
  type: actionTypes.UPDATE_MESSAGE
  payload: { id: number; text: string }
}

export interface SetExpansionAction {
  type: actionTypes.SET_EXPANSION
  payload: { id: number; expansion: QueryContext }
}

export interface GradeResponseAction {
  type: actionTypes.GRADE_RESPONSE
  payload: { id: number; rating?: number }
}

export interface CompleteStreamAction {
  type: actionTypes.COMPLETE_STREAM
}

export type MessageAction =
  | AddMessageAction
  | UpdateMessageAction
  | SetExpansionAction
  | CompleteStreamAction
  | GradeResponseAction
