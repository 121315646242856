import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducer from '../reducers/authReducer'
import { messageReducer } from '../reducers/messageReducer'
import { threadReducer } from '../reducers/threadReducer'
import { socketReducer } from '../reducers/socketReducer'
import { searchReducer } from '../reducers/searchReducer'
import { preferenceReducer } from '../reducers/preferenceReducer'

const rootReducer: any = combineReducers({
    auth: authReducer,
    message: messageReducer,
    thread: threadReducer,
    socket: socketReducer,
    search: searchReducer,
    preference: preferenceReducer,
})

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
}

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
export const persistor = persistStore(store)
