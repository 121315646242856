import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CoreIcon from '../../../Core/CoreIcon'
import { QueryContext } from '../../../../types/types'
import React, { memo } from 'react'

interface ExpansionComponentProps {
  context: QueryContext | undefined
}

export const QueryExpansion = memo(({ context }: ExpansionComponentProps) => {
  return (
    <ExpansionWrapper>
      <Grid item xs={3}>
        <ModalSubSectionHeading variant="h6">
          Decomposition
        </ModalSubSectionHeading>
        <MessageListWrapper>
          {context?.sub_questions?.map(
            (item: string, index: number) => (
              <MessageWrapper key={index}>
                <CoreIcon iconName="list-bullet" size={15} />
                <Message>{item}</Message>
              </MessageWrapper>
            ),
          )}
        </MessageListWrapper>
      </Grid>
      <Grid item xs={3}>
        <ModalSubSectionHeading variant="h6">
          Rephrasing
        </ModalSubSectionHeading>
        <MessageListWrapper>
          {context?.perspectives?.map(
            (item: string, index: number) => (
              <MessageWrapper key={index}>
                <CoreIcon iconName="list-bullet" size={15} />
                <Message>{item}</Message>
              </MessageWrapper>
            ),
          )}
        </MessageListWrapper>
      </Grid>
      <Grid item xs={3}>
        <ModalSubSectionHeading variant="h6">
          Tagging
        </ModalSubSectionHeading>
        <MessageListWrapper>
          {context?.tags?.map((item: string, index: number) => (
            <MessageWrapper key={index}>
              <CoreIcon iconName="list-bullet" size={15} />
              <Message>{item}</Message>
            </MessageWrapper>
          ))}
        </MessageListWrapper>
      </Grid>
      <Grid item xs={3}>
        <ModalSubSectionHeading variant="h6">
          Hyde
        </ModalSubSectionHeading>
        <MessageListWrapper>
          {context?.legal_document?.map(
            (item: string, index: number) => (
              <MessageWrapper key={index}>
                <Message>{item}</Message>
              </MessageWrapper>
            ),
          )}
        </MessageListWrapper>
      </Grid>
    </ExpansionWrapper>
  )
})

const ModalSubSectionHeading = styled(Typography)(({ theme }) => ({
  backgroundColor: '#5f6976',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '29px',
  padding: '13px 20px',
  flexGrow: 1,
}))

const ExpansionWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  rowGap: '16px',
  columnGap: '54px',
}))

const Message = styled('span')(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: '7px',
  padding: '10px',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '22px',
  color: '#000000',
}))

const MessageWrapper = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
}))

const MessageListWrapper = styled('ul')(({ theme }) => ({
  padding: '17px 0 0 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  margin: 0,
}))
