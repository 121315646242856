import { TopCourtCase, TopCourtCaseChunk } from '../../../../types/types'
import { RelevantCourtCaseItem } from './RelevantCourtCaseItem'
import { ItemsWrapper } from './components/ItemsWrapper'
import { FC } from 'react'

interface Props {
  top_court_cases: TopCourtCase[]
  top_court_case_chunks?: TopCourtCaseChunk[] | null
}

export const RelevantCourtCases: FC<Props> = ({
  top_court_cases,
  top_court_case_chunks = [],
}) => {
  return (
    <ItemsWrapper>
      {top_court_cases.map((item) => {
        const select_court_case_chunks = (top_court_case_chunks || []).filter(
          (item_chunk) => item_chunk.court_case_id === item.court_case_id,
        )

        return (
          <RelevantCourtCaseItem
            top_court_case={item}
            top_court_case_chunks={select_court_case_chunks}
            key={item.court_case_id}
          />
        )
      })}
    </ItemsWrapper>
  )
}
