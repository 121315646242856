import { ButtonBase } from '@mui/material'
import { styled } from '@mui/material/styles'

interface CoreButtonProps {
    text: string
    color: string
    textColor: string
    width?: number
    onClick: () => void
    type?: 'button' | 'submit'
    disabled?: boolean
}

export default function CoreButton({
    text,
    color,
    textColor,
    width,
    onClick,
    type,
    disabled,
}: CoreButtonProps) {
    return (
        <StyledButton
            onClick={onClick}
            color={color}
            textcolor={textColor}
            width={width || 150}
            type={type}
            disabled={disabled}
        >
            {text}
        </StyledButton>
    )
}

const StyledButton = styled(ButtonBase)<{
    color: string
    textcolor: string
    width: number
}>(({ theme, color, textcolor, width }) => ({
    display: 'flex',
    maxHeight: '50px',
    alignItems: 'center',
    padding: '15px 5px',
    fontSize: '16px',
    width: width + 'px',
    backgroundColor: color,
    color: textcolor,
    borderRadius: '15px',
    textTransform: 'uppercase',
    textAlign: 'center',
    transition: 'transform 0.2s ease, background-color 0.2s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    },
}))
