import { TopAct, TopActChunk } from '../../../../types/types'
import { RelevantActItem } from '../../../ActItem/RelevantActItem'
import { ItemsWrapper } from './components/ItemsWrapper'
import { FC } from 'react'

interface Props {
  top_acts: TopAct[]
  top_act_chunks?: TopActChunk[] | null
}

export const RelevantActs: FC<Props> = ({
  top_acts,
  top_act_chunks = [],
}) => {
  return (
    <ItemsWrapper>
      {top_acts.map((item) => {
        const select_top_act_chunks = (top_act_chunks || []).filter(
          (item_chunk) => item_chunk.act_id === item.act_id,
        )

        return (
          <RelevantActItem
            top_act={item}
            top_act_chunks={select_top_act_chunks}
            key={item.act_id}
          />
        )
      })}
    </ItemsWrapper>
  )
}
