import { memo } from 'react'

interface HeartIconProps {
    width?: number
    height?: number
    color?: string
}

export const HeartIcon = memo(
    ({ width = 20, height = 21, color = 'black' }: HeartIconProps) => {
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 20 21"
                fill={color}
                xmlns="http://www.w3.org/2000/svg"
            >
                <g>
                    <path
                        d="M18.2964 8.84336C17.9266 11.1881 11.2758 16.6772 9.76026 18.4843C8.86175 17.5873 3.80025 12.5234 3.03133 11.7553C-2.44197 6.30733 4.28625 -0.421427 9.76026 5.0018C13.1782 1.00396 19.737 3.50287 18.2964 8.84336Z"
                        fill={color}
                    />
                </g>
            </svg>
        )
    }
)
