import { styled } from '@mui/material/styles'
import { memo } from 'react'

interface LogoBlockProps {
    logo?: string
}

const LogoBlock = memo(({ logo }: LogoBlockProps) => {
    return (
        <LogoContainer>
            <img src="/images/legalgpt-logo.png" alt="legalgpt-logo.png" />
            <img
                src={logo || '/images/lextal-logo.png'}
                alt="lextal-logo.png"
            />
        </LogoContainer>
    )
})

export default LogoBlock

const LogoContainer = styled('div')(({ theme }) => ({
    zIndex: 0,
    top: '50px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    height: '225px',
    paddingBottom: '20px',
}))
