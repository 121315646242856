import { List } from '@mui/material'
import { styled } from '@mui/material/styles'
import ThreadListItem from '../ThreadListItem/ThreadListItem'
import { ThreadListItemType } from '../../../types/thread'
import { useAppSelector } from '../../../hooks/storeHooks'
import { memo } from 'react'

const ThreadList = memo(() => {
  const threads = useAppSelector((state) => state.thread.threadList)

  return (
    <ThreadListWrapper className="ScrollbarWrapperComponent">
      <ThreadListComponent>
        {threads.map((thread: ThreadListItemType) => (
          <ThreadListItem
            key={thread.id}
            thread={thread}
          />
        ))}
      </ThreadListComponent>
    </ThreadListWrapper>
  )
})

export default ThreadList

const ThreadListComponent = styled(List)(({ theme }) => ({
  padding: '0 8px 8px 8px',
  paddingRight: '0px',
  backgroundColor: theme.palette.background.paper,
  overflowY: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
  overflowX: 'hidden',
}))

const ThreadListWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '0 5px',
}))
