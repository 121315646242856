import {
    LOGOUT_USER,
    SET_AUTH_TOKEN,
    SET_AUTH_TOKEN_EXPIRES,
    SET_USER,
} from './types'
import { User } from '../reducers/authReducer'

export const setToken = (token: string) => {
    return {
        type: SET_AUTH_TOKEN,
        payload: token,
    }
}

export const setExpires = (timestamp: number) => {
    return {
        type: SET_AUTH_TOKEN_EXPIRES,
        payload: timestamp,
    }
}

export const setUser = (user: User) => {
    return {
        type: SET_USER,
        payload: user,
    }
}

export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    }
}
