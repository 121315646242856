import { Message, MessageAction, actionTypes } from '../types/message'

export function messagesReducer(
    state: Message[],
    action: MessageAction
): Message[] {
    switch (action.type) {
        case actionTypes.ADD_MESSAGE:
            return [...state].concat(action.payload)
        case actionTypes.UPDATE_MESSAGE:
            return state.map((msg) =>
                msg.id === action.payload.id
                    ? { ...msg, text: action.payload.text }
                    : msg
            )
        case actionTypes.SET_EXPANSION:
            return state.map((msg) =>
                msg.id === action.payload.id
                    ? { ...msg, context: action.payload.expansion }
                    : msg
            )
        case actionTypes.COMPLETE_STREAM:
            return state
        case actionTypes.GRADE_RESPONSE:
            return state.map((msg) =>
                msg.id === action.payload.id
                    ? { ...msg, rating: action.payload.rating }
                    : msg
            )
        default:
            return state
    }
}
