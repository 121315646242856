import React from 'react'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

interface CoreSwitchProps {
    color: string
    value: boolean
    checked?: boolean
    onChange: (value: boolean) => void
    disabled?: boolean
}

export default function CoreSwitch({
    value,
    onChange,
    color,
    disabled = false,
}: CoreSwitchProps) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked)
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <IOSSwitch
                sx={{ m: 1 }}
                value={value}
                checked={value}
                onChange={handleChange}
                disabled={disabled}
            />
        </div>
    )
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    transform: 'scale(1.5)',
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? theme.palette.secondary.light
                        : theme.palette.secondary.light,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.5)',
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor:
            theme.palette.mode === 'light'
                ? '#aaaaaa'
                : theme.palette.secondary.light,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}))
