import { Action } from '@reduxjs/toolkit'
import { actionTypes } from '../types/message'
import { LOGOUT_USER } from '../actions/types'

export enum SocketEvent {
    threadsList = 'threads.list',
    threadsListSearch = 'threads.list.search',
    threadsRetrieve = 'threads.retrieve',
    threadUpdate = 'threads.update',
    threadCreate = 'threads.create',
    tableCreate = 'tables.create',
    chatStreamStart = 'chats.stream.start',
    chatStreamStop = 'chats.stream.stop',
    chatStream = 'chats.stream',
    chatCreate = 'chats.create',
    chatContinue = 'chats.continue',
    chatResponseStatus = 'chats.response.status',
    stopGeneration = 'chats.stop',
    none = '',
}

interface InitialState {
    socket?: WebSocket
    socketEvent: SocketEvent
    isSocketConnected: boolean
}

const INITIAL_STATE: InitialState = {
    socket: undefined,
    socketEvent: SocketEvent.none,
    isSocketConnected: false,
}

interface ActionType extends Action {
    payload: any
}

export const socketReducer = (state = INITIAL_STATE, action: ActionType) => {
    const newState = { ...state }
    switch (action.type) {
        case actionTypes.SET_SOCKET:
            newState.socket = new WebSocket(action.payload)
            break
        case actionTypes.CLEAR_SOCKET:
            newState.socket = undefined
            break
        case actionTypes.SET_IS_SOCKET_CONNECTED:
            newState.isSocketConnected = action.payload
            break
        case actionTypes.SET_SOCKET_EVENT:
            newState.socketEvent = action.payload
            break
        case LOGOUT_USER:
            return INITIAL_STATE
        default:
            break
    }
    return newState
}
