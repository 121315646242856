import { memo } from 'react'

interface IconProps {
    width?: number
    height?: number
    color?: string
}

export const ArrowDownIcon = memo(
    ({ width = 19, height = 16, color = '#202020' }: IconProps) => {
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.31959 16L18.2566 0.6H0.382563L9.31959 16Z"
                    fill={color}
                />
            </svg>
        )
    }
)
