import {
    LOGIN_USER,
    LOGOUT_USER,
    SET_AUTH_TOKEN,
    SET_AUTH_TOKEN_EXPIRES,
    SET_USER,
} from '../actions/types'
import { Action } from '@reduxjs/toolkit'

export interface User {
    id: number
    company_id?: number
    name: string
    email: string
    phone: string
    user_context?: string
    password: string
}

interface AuthState {
    token: string | null
    expires: number | null
    user: User | null
}

const INIT_USER = {
    id: -1,
    company_id: undefined,
    name: '',
    email: '',
    phone: '',
    user_context: undefined,
    password: '',
}

const INITIAL_STATE: AuthState = {
    token: null,
    expires: null,
    user: INIT_USER,
}

interface ActionType extends Action {
    payload: any
}

const authReducer = (state = INITIAL_STATE, action: ActionType) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                token: action.payload.token,
                expires: action.payload.expires,
                user: action.payload.user,
            }
        case SET_AUTH_TOKEN:
            return {
                ...state,
                token: action.payload,
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            }
        case SET_AUTH_TOKEN_EXPIRES:
            return {
                ...state,
                expires: action.payload,
            }
        case LOGOUT_USER:
            return INITIAL_STATE
        default:
            return state
    }
}
export default authReducer
