import './App.css'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme/theme'
import ChatPage from './pages/ChatPage/ChatPage'
import { GlobalStyles } from '@mui/styled-engine'
import {
    AppContextProvider,
    MessagesProvider,
} from './context/AppContextProvider'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom'
import LoginPage from './pages/Auth/LoginPage'
import SignupAccountPage from './pages/Auth/SignupAccountPage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ContentWrapper from './components/Auxilary/ContentWrapper'
import { PreferencesPage } from './pages/Preferences/PreferencesPage'

function App() {
    return (
        <ThemeProvider theme={theme}>
            {globalScrollbarStyles}
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                stacked
            />

            <Router>
                <ContentWrapper>
                    <MessagesProvider>
                        <AppContextProvider>
                            <Routes>
                                <Route
                                    path="/login"
                                    element={<LoginPage />}
                                />
                                <Route
                                    path="/signup"
                                    element={<SignupAccountPage />}
                                />
                                <Route
                                    path="/chat"
                                    element={<ChatPage />}
                                />
                                <Route
                                    path="/preferences"
                                    element={<PreferencesPage />}
                                />
                                <Route
                                    path="*"
                                    element={
                                        <Navigate to="/chat" replace />
                                    }
                                />
                            </Routes>
                        </AppContextProvider>
                    </MessagesProvider>
                </ContentWrapper>
            </Router>
        </ThemeProvider>
    )
}

export default App

const globalScrollbarStyles = (
    <GlobalStyles
        styles={{
            '.custom-scrollbar::-webkit-scrollbar': {
                backgroundColor: theme.palette.background.paper,
                width: '28px',
            },
            '.custom-scrollbar::-webkit-scrollbar-thumb': {
                borderTop: '14px solid ' + theme.palette.background.paper,
                borderRight: '6px solid ' + theme.palette.background.paper,
                borderLeft: '2px solid ' + theme.palette.background.paper,
            },
            '.yellow::-webkit-scrollbar-thumb': {
                backgroundColor: 'yellow',
            },
            '.grey::-webkit-scrollbar-thumb': {
                backgroundColor: 'grey',
            },
            '.custom-scrollbar': {
                scrollbarColor: 'transparent',
            },
        }}
    />
)
