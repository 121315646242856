import { TopIntentDocument, TopIntentDocumentChunk } from '../../../../types/types'
import { ItemsWrapper } from './components/ItemsWrapper'
import { FC } from 'react'
import { RelevantIntentDocumentItem } from './RelevantIntentDocumentItem'

interface Props {
  top_intent_documents: TopIntentDocument[]
  top_intent_document_chunks?: TopIntentDocumentChunk[] | null
}

export const RelevantIntentDocuments: FC<Props> = ({
  top_intent_documents,
  top_intent_document_chunks = [],
}) => {
  return (
    <ItemsWrapper>
      {top_intent_documents.map((item) => {
        const select_top_intent_document_chunks = (top_intent_document_chunks || []).filter(
          (item_chunk) => item_chunk.document_id === item.document_id,
        )

        return (
          <RelevantIntentDocumentItem
            top_intent_document={item}
            top_intent_document_chunks={select_top_intent_document_chunks}
            key={item.document_id}
          />
        )
      })}
    </ItemsWrapper>
  )
}
