import { memo } from 'react'

interface IconProps {
    width?: number
    height?: number
    color?: string
}

export const DoneIcon = memo(
    ({ width = 25, height = 25, color = 'black' }: IconProps) => {
        return (
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.7069 3.99002C23.1852 4.36995 23.2649 5.0657 22.885 5.54401L11.1709 20.2912C10.9664 20.5487 10.6579 20.7018 10.3291 20.709C10.0003 20.7162 9.68539 20.5767 9.46977 20.3284L1.52095 11.175C1.12043 10.7138 1.16964 10.0152 1.63085 9.61472C2.09206 9.21421 2.79064 9.26341 3.19116 9.72462L10.267 17.8727L21.1529 4.16814C21.5328 3.68983 22.2285 3.61008 22.7069 3.99002Z"
                      fill="white"
                />
            </svg>
        )
    }
)
