import React, {
    ReactNode,
    createContext,
    useContext,
    useReducer,
    useState,
} from 'react'
import { AppContext } from './AppContext'
import { Thread } from '../types/thread'
import { Company, Settings, User } from '../types/user'
import { messagesReducer } from '../hooks/messageReducer'
import { Message } from '../types/message'

interface AppProviderProps {
    children: ReactNode
}

export const AppContextProvider: React.FC<AppProviderProps> = ({
    children,
}) => {
    const [user, setUser] = useState<User | null>(null)
    const [activeThread, setActiveThread] = useState<Thread | null>(null)
    const [company, setCompany] = useState<Company | null>(null)
    const [settings, setSettings] = useState<Settings | null>(null)

    const contextValue = {
        user,
        company,
        settings,
        activeThread,
        setUser,
        setActiveThread,
        setSettings,
        setCompany,
    }

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    )
}

const MessagesStateContext = createContext<Message[]>([])
const MessagesDispatchContext = createContext<React.Dispatch<any>>(() => {})

interface MessagesProviderProps {
    children: ReactNode
}

export const MessagesProvider: React.FC<MessagesProviderProps> = ({
    children,
}) => {
    const [messages, dispatch] = useReducer(messagesReducer, [])

    return (
        <MessagesStateContext.Provider value={messages}>
            <MessagesDispatchContext.Provider value={dispatch}>
                {children}
            </MessagesDispatchContext.Provider>
        </MessagesStateContext.Provider>
    )
}
export const useMessagesDispatch = () => useContext(MessagesDispatchContext)
