import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store/store'
import { Provider } from 'react-redux'

const resizeObserverLoopErrRe = /^ResizeObserver loop limit exceeded/
window.addEventListener('error', (event) => {
    if (resizeObserverLoopErrRe.test(event.message)) {
        event.stopImmediatePropagation()
    }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
)
