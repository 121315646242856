import React from 'react'

interface GenyPodIconProps {
    iconName: string
    color?: 'white' | 'black'
    size?: number
}

const CoreIcon: React.FC<GenyPodIconProps> = ({
    iconName,
    color = 'currentColor',
    size = 24,
}) => {
    const iconPath = `${process.env.PUBLIC_URL}/icons/${iconName}.svg`
    const style = {
        filter: color === 'white' ? 'invert(100%)' : 'none',
        width: `${size}px`,
        height: `${size}px`,
    }

    return <img src={iconPath} alt={iconName} style={style} />
}

export default CoreIcon
