import { styled } from '@mui/material/styles'

export const ItemWrapper = ({ children }: { children: any }) => {
    return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    backgroundColor: '#FFFFFF',
    borderRadius: '7px',
    padding: '10px 20px',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000000',
}))
