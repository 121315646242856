import React, { useRef } from 'react'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import { IconButton, InputAdornment } from '@mui/material'
import CoreIcon from '../../Core/CoreIcon'
import { useGetCurrentThreadInputText } from '../../../hooks/useGetCurrentThreadInputText'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { actionTypes } from '../../../types/message'

interface ChatInputProps {
  isLoading: boolean
  handleNewUserMessage: (text: string) => void
  handleStopGeneration: () => void
}

const ChatInput: React.FC<ChatInputProps> = ({
  isLoading,
  handleNewUserMessage,
  handleStopGeneration,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const dispatch = useAppDispatch()
  const currentThreadId = useAppSelector((state) => state.thread.currentThreadId)
  const currentThreadInputText = useGetCurrentThreadInputText(currentThreadId)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value.trim()) {
      dispatch({
        type: actionTypes.SET_CURRENT_THREAD_INPUT_TEXT,
        payload: {
          thread_id: currentThreadId,
          value: '',
        },
      })
    } else {
      dispatch({
        type: actionTypes.SET_CURRENT_THREAD_INPUT_TEXT,
        payload: {
          thread_id: currentThreadId,
          value: event.target.value,
        },
      })
    }
  }

  const handleSendClick = () => {
    if (isLoading) {
      return
    }
    if (!currentThreadInputText.trim()) {
      return
    }
    handleNewUserMessage(currentThreadInputText.trim())
    dispatch({
      type: actionTypes.SET_CURRENT_THREAD_INPUT_TEXT,
      payload: {
        thread_id: currentThreadId,
        value: '',
      },
    })
  }

  const handleAttachmentClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      console.log(file.name)
    }
  }

  return (
    <ChatInputWrapper>
      <ChatInputContainer>
        <StyledTextField
          className="custom-scrollbar grey"
          value={currentThreadInputText}
          onChange={handleInputChange}
          autoFocus
          placeholder="Ask a legal question..."
          multiline
          minRows={1}
          variant="outlined"
          onKeyDown={(
            event: React.KeyboardEvent<HTMLInputElement>,
          ) => {
            if (event.key === 'Enter') handleSendClick()
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{
                  marginTop: '0px',
                  marginLeft: '-14px',
                }}
              >
                <IconButton
                  onClick={handleAttachmentClick}
                  style={{ marginTop: '35px' }}
                >
                  <CoreIcon
                    iconName="paperclip-icon"
                    color="white"
                    size={40}
                  />
                </IconButton>
              </InputAdornment>
            ),
            style: { alignItems: 'flex-start' },
          }}
        />
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />

        {isLoading ? (
          <ChatStopButton
            onClick={handleStopGeneration}
            color="white"
          >
            <CoreIcon iconName="stop-icon" size={40} />
          </ChatStopButton>
        ) : (
          <ChatInputButton
            onClick={handleSendClick}
          >
            Send
          </ChatInputButton>
        )}
      </ChatInputContainer>
    </ChatInputWrapper>
  )
}

export default ChatInput

const ChatInputWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '15px 20px',
  backgroundColor: theme.palette.secondary.main,
  marginBottom: '25px',
  borderRadius: '15px',
  marginRight: '43px',
}))

const ChatInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  padding: 0,
  flexGrow: 1,
}))

const ChatInputButton = styled('button')(({ theme }) => ({
  display: 'flex',
  maxHeight: '50px',
  padding: '15px 20px',
  fontSize: '18px',
  backgroundColor: theme.palette.warning.main,
  borderRadius: '15px',
  textTransform: 'uppercase',
  transition: 'transform 0.2s ease, background-color 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  cursor: 'pointer',
  border: 'none',
  width: '5rem',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ChatStopButton = styled(ChatInputButton)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.main,
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  maxHeight: '300px',
  overflow: 'auto',
  '& .MuiInputBase-input': {
    fontSize: '18px',
    padding: '10px 14px',
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    borderRadius: '7px',
    backgroundColor: theme.palette.primary.dark,
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-inputMultiline': {
    minHeight: '48px',
  },
}))
