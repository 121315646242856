import { memo } from 'react'
import { styled } from '@mui/material/styles'
import { CloseIcon } from '../../../icons/CloseIcon'
import { useDispatch } from 'react-redux'
import { actionTypes } from '../../../types/message'
import { SocketEvent } from '../../../reducers/socketReducer'

export const ThreadSearchTags = memo(({ tags }: { tags: string[] }) => {
    const dispatch = useDispatch()
    const removeTagHandler = (tag: string) => {
        dispatch({ type: actionTypes.REMOVE_SEARCH_TAG, payload: { tag } })
        dispatch({
            type: actionTypes.SET_SOCKET_EVENT,
            payload: SocketEvent.threadsListSearch,
        })
    }
    return (
        <ThreadSearchTagsContainer>
            <SearchTags>
                {tags.map((tag) => (
                    <Tags key={tag}>
                        <span>{tag}</span>
                        <IconWrapper onClick={() => removeTagHandler(tag)}>
                            <CloseIcon />
                        </IconWrapper>
                    </Tags>
                ))}
            </SearchTags>
        </ThreadSearchTagsContainer>
    )
})

const ThreadSearchTagsContainer = styled('div')(({ theme }) => ({
    margin: '0 10px 0 10px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.52px',
}))

const SearchTags = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '7px',
}))

const Tags = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: '#FFE500',
    gap: '5px',
    borderRadius: '7px',
    textTransform: 'uppercase',
}))

const IconWrapper = styled('span')(({ theme }) => ({
    display: 'flex',
    cursor: 'pointer',
}))
