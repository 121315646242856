import * as React from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector'
import { StepIconProps } from '@mui/material/StepIcon'
import _ from 'lodash'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'transparent',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'transparent',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: 'transparent',
        borderRadius: 1,
    },
}))

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? theme.palette.grey[700]
            : theme.palette.secondary.dark,
    zIndex: 1,
    color: '#000',
    fontWeight: 700,
    fontSize: '26px',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.palette.warning.main,
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.secondary.dark,
    }),
}))

interface AdditionalStepIconProps {
    icons?: { [index: string]: React.ReactElement }
}

type StepIconFullProps = StepIconProps & AdditionalStepIconProps

const ColorlibStepIcon: React.FC<StepIconFullProps> = (props) => {
    const { active, completed, className, icons } = props

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {!_.isEmpty(icons) ? icons[String(props.icon)] : String(props.icon)}
        </ColorlibStepIconRoot>
    )
}

interface CustomStepperProps {
    steps: string[]
    icons?: { [index: string]: React.ReactElement }
    step: number
}

const CustomStepper: React.FC<CustomStepperProps> = ({
    steps,
    icons,
    step = 1,
}) => {
    return (
        <Stack sx={{ width: '50%' }} spacing={4}>
            <Stepper
                alternativeLabel
                activeStep={step}
                connector={<ColorlibConnector />}
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={(props) => (
                                <ColorlibStepIcon {...props} icons={icons} />
                            )}
                        >
                            <span style={{ fontSize: '18px' }}>{label}</span>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    )
}

export default CustomStepper
