import parseJwt from './parseJWT'

const checkTokenExpired = (token: string) => {
    if (!token) return
    try {
        const parsedToken = parseJwt(token)
        const dt = Date.now()
        const expires = parsedToken.exp * 1000

        return dt >= expires
    } catch (error) {
        console.error('error checking token expiration time', error)
        return true
    }
}
export default checkTokenExpired
