import { FC, useState } from 'react'
import { styled } from '@mui/material/styles'
import { TopEuLaw, TopEuLawChunk } from '../../../../types/types'
import { ItemWrapper } from './components/ItemWrapper'
import { EUIcon } from '../../../../icons/EUIcon'

type PropsT = {
  top_eu_law: TopEuLaw
  top_eu_law_chunks?: TopEuLawChunk[]
}

export const RelevantEuLawItem: FC<PropsT> = ({
  top_eu_law,
  top_eu_law_chunks = [],
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ItemWrapper>
      <Header>
        <IconWrapper>
          <EUIcon />
        </IconWrapper>
        <Name>{top_eu_law.eu_law_name}</Name>
        <ButtonPart>
          <Hits>{top_eu_law.hits}</Hits>
        </ButtonPart>
        <ExpandButton
          onClick={toggleAccordion}
          className={isOpen ? 'open' : ''}
        />
      </Header>
      {isOpen && (
        <>
          <LinkWrapper>
            Link:{' '}
            <a href={top_eu_law.eu_law_url} target="_blank">
              {top_eu_law.eu_law_url}
            </a>
          </LinkWrapper>

          {top_eu_law_chunks.map((item, idx) => {
            return (
              <Wrapper key={idx}>
                <Title>
                  {item.part_no} {item.hits}
                </Title>
                <Text>{item.chunk_content}</Text>
              </Wrapper>
            )
          })}
        </>
      )}
    </ItemWrapper>
  )
}

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}))

const ButtonPart = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const Hits = styled('span')(({ theme }) => ({
  backgroundColor: '#0094a2',
  borderRadius: '50%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',

  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '22px',
  color: 'white',
}))

const ExpandButton = styled('div')(({ theme }) => ({
  width: 0,
  height: 0,
  borderLeft: '12px solid transparent',
  borderRight: '12px solid transparent',
  borderTop: '18px solid #000',
  cursor: 'pointer',
  margin: '16px 8px',
  transform: 'rotate(0deg)',
  transition: 'transform 0.3s',

  '&.open': {
    transform: 'rotate(180deg)',
  },
}))

const LinkWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  height: '44px',
  lineHeight: '19px',
  fontWeight: 400,
  gap: '4px',
}))

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
}))

const Name = styled('span')(({ theme }) => ({
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '22px',
  color: '#000000',
  flexGrow: 1,
}))

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '10px',
}))

const Title = styled('span')(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: 700,
  textTransform: 'capitalize',
}))

const Text = styled('span')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 400,
}))
