import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import ChatMessage from '../ChatMessage/ChatMessage'
import { Typography } from '@mui/material'
import { useAppSelector } from '../../../hooks/storeHooks'
import { useGetCurrentThreadLoadingStep } from '../../../hooks/useGetCurrentThreadLoadingStep'
import { useGetCurrentThread } from '../../../hooks/useGetCurrentThread'

const ChatPane: React.FC = () => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const chatPaneRef = useRef<null | HTMLDivElement>(null)
  const [isAtBottom, setIsAtBottom] = useState(true)
  const [threadIdle, setThreadIdle] = useState(true)

  const currentThreadId = useAppSelector(state => state.thread.currentThreadId)
  const currentThreadLoadingStep = useGetCurrentThreadLoadingStep(currentThreadId)
  const currentThread = useGetCurrentThread(currentThreadId)

  useEffect(() => {
    const handleScroll = () => {
      if (!chatPaneRef.current) return
      const { scrollTop, scrollHeight, clientHeight } =
        chatPaneRef.current
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10
      setIsAtBottom(atBottom)
    }

    const chatPane = chatPaneRef.current
    if (chatPane) {
      chatPane.addEventListener('scroll', handleScroll)
      handleScroll()
    }

    return () => {
      if (chatPane) {
        chatPane.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (isAtBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [currentThread?.messages])

  useEffect(() => {
    if (threadIdle) {
      setThreadIdle(false)
    }
  }, [currentThreadId])

  useEffect(() => {
    if (!threadIdle) {
      setThreadIdle(true)
    }
  }, [threadIdle])

  return (
    <ChatPaneContainer ref={chatPaneRef} className="custom-scrollbar grey">
      <Typography typography="h5" color="white">
        {currentThread?.title}
      </Typography>

      {((threadIdle && currentThread)
        ? currentThread.messages
        : []
      ).map((message, index) => (
        <ChatMessage
          key={index}
          message={message}
          currentThreadLoadingStep={currentThreadLoadingStep}
        />
      ))}
      <div ref={messagesEndRef} />
    </ChatPaneContainer>
  )
}

export default ChatPane

const ChatPaneContainer = styled('div')({
  display: 'flex',
  padding: '20px 0px',
  flexDirection: 'column',
  flexGrow: 1,
  gap: '50px',
  overflowY: 'auto',
  paddingRight: '43px',
})
