import React, { memo, useEffect, useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import CoreIcon from '../../Core/CoreIcon'
import { useDebounce } from '../../../hooks/useDebounce'
import { useDispatch } from 'react-redux'
import { actionTypes } from '../../../types/message'
import { SocketEvent } from '../../../reducers/socketReducer'

const ThreadListSearch = memo(() => {
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState('')
    const searchValueDebounce = useDebounce(searchValue, 500)

    useEffect(() => {
        dispatch({
            type: actionTypes.SET_SEARCH_VALUE,
            payload: searchValueDebounce,
        })
        dispatch({
            type: actionTypes.SET_SOCKET_EVENT,
            payload: SocketEvent.threadsListSearch,
        })
    }, [searchValueDebounce, dispatch])

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && searchValue.trim()) {
            setSearchValue('')
            dispatch({
                type: actionTypes.ADD_SEARCH_TAG,
                payload: searchValue.toLowerCase(),
            })
            dispatch({
                type: actionTypes.SET_SOCKET_EVENT,
                payload: SocketEvent.threadsListSearch,
            })
        }
    }

    return (
        <SearchContainer>
            <SearchIconWrapper>
                <CoreIcon iconName="search-icon" size={32} />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search..."
                inputProps={{ 'aria-label': 'search' }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyDown}
            />
        </SearchContainer>
    )
})

export default ThreadListSearch

const SearchContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 1),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 1),
    },
    margin: '0 10px 0 10px',
    height: '50px',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    top: '6px',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    height: '50px',
    fontSize: '22px',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}))
