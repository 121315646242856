import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { ButtonRow, FormRow, InputLabel } from './StyledWidgets'
import CoreInput, { CoreInputType } from '../../Core/CoreInput'
import { CompanyRegistrationFormData } from '../../../pages/Auth/SignupAccountPage'
import CoreButton from '../../Core/CoreButton'

interface RegisterCompanyProps {
    companyFormData: CompanyRegistrationFormData
    handleChangeCompany: (fieldName: string, fieldValue: any) => void
    handlePrevStep: () => void
}

const RegisterCompany: React.FC<RegisterCompanyProps> = ({
    companyFormData,
    handleChangeCompany,
    handlePrevStep,
}) => {
    const theme = useTheme()

    return (
        <React.Fragment>
            <FormRow>
                <Typography typography="body1" fontSize={26}>
                    Enter your company details
                </Typography>
            </FormRow>

            <FormRow>
                <InputLabel typography="body1">Company legal name</InputLabel>
                <CoreInput
                    value={companyFormData?.name || ''}
                    placeholder="Enter company name"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Text}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChangeCompany('name', value)
                    }
                    required
                />
            </FormRow>

            <FormRow>
                <InputLabel typography="body1">Registration code</InputLabel>
                <CoreInput
                    value={companyFormData?.registrationCode || ''}
                    placeholder="Official registration number"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Text}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChangeCompany('registrationCode', value)
                    }
                    required
                />
            </FormRow>

            <FormRow>
                <InputLabel typography="body1">Legal address</InputLabel>
                <CoreInput
                    value={companyFormData?.legalAddress || ''}
                    placeholder="Enter address"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Text}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChangeCompany('legalAddress', value)
                    }
                    required
                />
            </FormRow>

            <FormRow>
                <InputLabel typography="body1">VAT code</InputLabel>
                <CoreInput
                    value={companyFormData?.vatCode || ''}
                    placeholder="Enter VAT code"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Text}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChangeCompany('vatCode', value)
                    }
                    required
                />
            </FormRow>

            <FormRow>
                <InputLabel typography="body1">Company email</InputLabel>
                <CoreInput
                    value={companyFormData?.email || ''}
                    placeholder="Enter email"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Email}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChangeCompany('email', value)
                    }
                    required
                />
            </FormRow>

            <FormRow>
                <InputLabel typography="body1">Enter phone</InputLabel>
                <CoreInput
                    value={companyFormData?.phone || ''}
                    placeholder="Enter phone"
                    backgroundColor={theme.palette.common.white}
                    type={CoreInputType.Text}
                    color={theme.palette.common.black}
                    onChange={(value: string) =>
                        handleChangeCompany('phone', value)
                    }
                    required
                />
            </FormRow>

            <ButtonRow>
                <CoreButton
                    text="Go back"
                    color={theme.palette.background.default}
                    width={200}
                    textColor={theme.palette.common.white}
                    onClick={handlePrevStep}
                    type="button"
                />
                <CoreButton
                    text="Create account"
                    color={theme.palette.warning.main}
                    textColor={theme.palette.common.black}
                    width={200}
                    onClick={() => {}}
                    type="submit"
                />
            </ButtonRow>
        </React.Fragment>
    )
}

export default RegisterCompany
