import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    palette: {
        primary: {
            light: '#3C3A3A',
            main: '#343434',
            dark: '#252525',
        },
        secondary: {
            light: '#0092A4',
            main: '#292B37',
            dark: '#9D9783',
        },
        warning: {
            main: '#FFE500',
        },
        background: {
            paper: '#32343D',
            default: '#6F7B89',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#C0C6D6',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        button: {
            fontWeight: 700,
        },
    },
})

export default theme
