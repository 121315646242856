import { memo } from 'react'

interface IconProps {
    width?: number
    height?: number
    color?: string
}

export const CopyIcon = memo(
    ({ width = 16, height = 18, color = '#F3F3F3' }: IconProps) => {
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 16 18"
                fill={color}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.6179 13.9615C14.1037 13.9615 15.3077 12.7564 15.3077 11.2697V3.19179C15.3077 1.70647 14.1034 0.5 12.6179 0.5H7.22822C5.74248 0.5 4.53846 1.70516 4.53846 3.19179V4.53846H3.19231C1.70841 4.53846 0.5 5.74362 0.5 7.23025V15.3082C0.5 16.7935 1.70424 18 3.18975 18H8.57948C10.0652 18 11.2692 16.7935 11.2692 15.3051V13.9615H12.6179ZM1.84615 7.56731C1.84615 6.63798 2.59876 5.88462 3.52951 5.88462H8.23972C9.16941 5.88462 9.92308 6.63761 9.92308 7.56731V14.9712C9.92308 15.9005 9.17047 16.6538 8.23972 16.6538H3.52951C2.59982 16.6538 1.84615 15.9009 1.84615 14.9712V7.56731ZM5.88462 3.52829C5.88462 2.59927 6.63722 1.84615 7.56797 1.84615H12.2782C13.2079 1.84615 13.9615 2.59914 13.9615 3.52885V10.9327C13.9615 11.862 13.2114 12.6154 12.279 12.6154H11.2686V7.22796C11.2686 5.74259 10.0603 4.53846 8.57659 4.53846H5.88462V3.52829Z"
                    fill={color}
                />
            </svg>
        )
    }
)
