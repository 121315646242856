import { memo } from 'react'

interface IconProps {
    width?: number
    height?: number
    color?: string
}

export const CloseIcon = memo(
    ({ width = 15, height = 15, color = 'black' }: IconProps) => {
        return (
            <svg
                width={width}
                height={height}
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.82395 2.80078C3.0799 2.55764 3.48448 2.56801 3.72763 2.82395L7.3125 6.5975L10.8974 2.82395C11.1405 2.56801 11.5451 2.55764 11.801 2.80078C12.057 3.04393 12.0674 3.44852 11.8242 3.70446L8.19416 7.52557L11.8242 11.3467C12.0674 11.6026 12.057 12.0072 11.801 12.2504C11.5451 12.4935 11.1405 12.4831 10.8974 12.2272L7.3125 8.45363L3.72763 12.2272C3.48448 12.4831 3.0799 12.4935 2.82395 12.2504C2.56801 12.0072 2.55764 11.6026 2.80078 11.3467L6.43084 7.52557L2.80078 3.70446C2.55764 3.44852 2.56801 3.04393 2.82395 2.80078Z"
                    fill={color}
                />
            </svg>
        )
    }
)
