import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

export const InputLabel = styled(Typography)(({ theme }) => ({
    width: '200px',
    fontSize: '18px',
    textWrap: 'wrap',
}))

export const FormRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '35px',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    alignContent: 'flex-start',
    height: '70px',
}))

export const ButtonRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'flex-end',
    marginTop: '20px',
    height: '70px',
}))
