import { memo } from 'react'

interface IconProps {
    width?: number
    height?: number
    color?: string
}

export const IntentDocumentIcon = memo(
    ({ width = 40, height = 40, color = 'black' }: IconProps) => {
        return (
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M32.62 29.785L25.97 23.52C27.93 21.035 29.085 17.955 29.085 14.56C29.085 6.51 22.575 0 14.525 0C6.51 0 0 6.51 0 14.56C0 22.575 6.51 29.085 14.525 29.085C17.92 29.085 21 27.9299 23.485 25.97L29.75 32.62C29.785 32.655 29.82 32.69 29.82 32.69C30.625 33.4599 31.92 33.4249 32.6549 32.62C33.46 31.8151 33.425 30.52 32.6199 29.785L32.62 29.785ZM3.22 14.56C3.22 8.33 8.295 3.255 14.525 3.255C20.755 3.255 25.83 8.33 25.83 14.56C25.83 20.79 20.755 25.865 14.525 25.865C8.295 25.865 3.22 20.79 3.22 14.56Z"
                    fill="black" />
                <path
                    d="M16.45 20.9999C16.45 21.9277 15.6978 22.6799 14.77 22.6799C13.8422 22.6799 13.09 21.9278 13.09 20.9999C13.09 20.072 13.8422 19.3198 14.77 19.3198C15.6978 19.3198 16.45 20.072 16.45 20.9999Z"
                    fill="black" />
                <path
                    d="M14.595 6.61502C11.41 6.61502 9.72999 8.82008 9.41499 11.095C9.34504 11.5501 9.73002 11.97 10.1849 11.97H11.4099C11.7949 11.97 12.1099 11.69 12.1799 11.34C12.3199 10.465 12.8799 9.37999 14.6299 9.37999C15.9599 9.37999 16.5199 10.08 16.7299 10.4999C17.0449 11.13 17.0099 11.83 16.6248 12.39C16.2399 12.915 15.8899 13.1599 15.4698 13.4749C14.4547 14.2099 13.6147 14.9449 13.4397 17.0449C13.4047 17.5 13.7547 17.885 14.2097 17.885H15.3997C15.7847 17.885 16.1347 17.5699 16.1696 17.185C16.2396 16.275 16.4847 16.135 17.0097 15.75C17.4997 15.4 18.1647 14.9449 18.8296 14.035C19.8446 12.6699 19.9847 10.85 19.1796 9.27496C18.34 7.5949 16.625 6.61499 14.5949 6.61499L14.595 6.61502Z"
                    fill="black" />
            </svg>
        )
    }
)
