export interface TopAct {
  act_id: string
  act_name: string
  act_url: string
  avg_hits_per_part: number
  hits: number
  abbreviation?: string
}

export interface TopActChunk {
  act_id: string
  chunk_content: string
  chunk_no: number
  clause_no: number
  clause_variation_no?: number
  hits: number
  paragraph_name: string
  paragraph_no: number
  paragraph_variation_no?: number
  part_no: number
}

export interface TopEuLaw {
  eu_law_id: string
  eu_law_name: string
  eu_law_url: string
  hits: number
  avg_hits_per_part: number
}

export interface TopEuLawChunk {
  eu_law_id: string
  part_no: number
  chunk_no: number
  chunk_content: string
  hits: number
}

export interface TopCourtCase {
  court_case_id: string
  court_case_name: string
  court_name: string
  case_no: string
  verdict: string
  court_case_url: string
  hits: number
}

export interface TopCourtCaseChunk {
  court_case_id: string
  chunk_no: number
  chunk_content: string
  hits: number
}

export interface TopIntentDocumentChunk {
  document_id: number
  part_no: number
  chunk_no: number
  chunk_content: string
  hits: number
}

export interface TopIntentDocument {
  document_id: number
  document_name: string
  document_url: string
  hits: number
}

export interface QueryContext {
  id: string
  legal_document: string[]
  perspectives: string[]
  sub_questions: string[]
  tags: string[]
  top_act_chunks: TopActChunk[]
  top_acts: TopAct[]
  top_court_case_chunks: TopCourtCaseChunk[]
  top_court_cases: TopCourtCase[]
  top_eu_law_chunks: TopEuLawChunk[]
  top_eu_laws: TopEuLaw[]
  top_intent_document_chunks: TopIntentDocumentChunk[]
  top_intent_documents: TopIntentDocument[]
}

export enum MessageTypeEnum {
  question = 'question',
  answer = 'answer',
}

export type MessagePublicType = {
  id: string
  text?: string
  type: MessageTypeEnum
  is_legal: boolean
  created_at: string | number
  thread_id?: string
  expanded_answer_id?: string
  context?: QueryContext
}

export type ThreadMainType = {
  id: string
  title: string
  created_at: string
  updated_at: string

  tags: string[]
}

export type ThreadMessageType = ThreadMainType & {
  messages: MessagePublicType[]
}

export type ThreadListResponseType = {
  threads: ThreadMainType[]
}
