import { Action } from '@reduxjs/toolkit'
import { actionTypes } from '../types/message'
import { LOGOUT_USER } from '../actions/types'

interface InitialState {
    searchValue: string
    searchTags: string[]
}

const INITIAL_STATE: InitialState = {
    searchValue: '',
    searchTags: [],
}

interface ActionType extends Action {
    payload: any
}

export const searchReducer = (state = INITIAL_STATE, action: ActionType) => {
    const newState = { ...state }
    switch (action.type) {
        case actionTypes.SET_SEARCH_VALUE:
            newState.searchValue = action.payload
            break
        case actionTypes.ADD_SEARCH_TAG:
            if (!newState.searchTags.includes(action.payload)) {
                newState.searchTags = [...newState.searchTags, action.payload]
            }
            break
        case actionTypes.REMOVE_SEARCH_TAG:
            newState.searchTags = newState.searchTags.filter(
                (item) => item !== action.payload.tag
            )
            break
        case LOGOUT_USER:
            return INITIAL_STATE
        default:
            break
    }
    return newState
}
