import { memo, useEffect, useRef, useState } from 'react'
import { ArrowDownIcon } from '../../icons/ArrowDownIcon'
import { styled } from '@mui/material/styles'

interface Option {
    value: string
    label: string
}

interface CustomSelectProps {
    options: Option[]
    placeholder: string
    selectedActs: string[]
    addActHandler: (act: string) => void
}
export const CustomSelectComponent = memo(
    ({ options, placeholder, addActHandler }: CustomSelectProps) => {
        const [isOpen, setIsOpen] = useState<boolean>(false)
        const [selectedAct, setSelectedAct] = useState<string>('')

        const selectRef = useRef<HTMLDivElement>(null)

        const handleSelectOption = (option: string) => {
            setSelectedAct(option)
            addActHandler(option)
            setIsOpen(false)
        }

        const handleClickOutside = (event: MouseEvent) => {
            if (
                selectRef.current &&
                !selectRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }, [])

        return (
            <SelectWrapper
                ref={selectRef}
                style={{ borderRadius: isOpen ? '7px 7px 0 0' : '7px' }}
            >
                <SelectContentWrapper onClick={() => setIsOpen(!isOpen)}>
                    <SelectValueWrapper>
                        <span>{selectedAct ? selectedAct : placeholder}</span>
                        <SelectIconWrapper>
                            <ArrowDownIcon />
                        </SelectIconWrapper>
                    </SelectValueWrapper>
                    {isOpen && (
                        <SelectOptionsWrapper>
                            {options.map(({ label, value }) => (
                                <SelectOption
                                    style={{
                                        background:
                                            value === selectedAct
                                                ? '#f0f0f0'
                                                : '',
                                    }}
                                    key={value}
                                    onClick={() => handleSelectOption(value)}
                                >
                                    {label}
                                </SelectOption>
                            ))}
                        </SelectOptionsWrapper>
                    )}
                </SelectContentWrapper>
            </SelectWrapper>
        )
    }
)

const SelectWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '7px',
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
}))

const SelectValueWrapper = styled('span')(({ theme }) => ({
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '22px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 16px',
    borderRadius: '4px',
}))

const SelectIconWrapper = styled('span')(({ theme }) => ({
    display: 'flex',
}))
const SelectContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
}))
const SelectOptionsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    background: theme.palette.common.white,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    zIndex: 10,
}))

const SelectOption = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '10px 16px',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '22px',
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    ':hover': {
        background: '#f0f0f0',
    },
}))
