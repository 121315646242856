import { styled } from '@mui/material/styles'

export const ItemsWrapper = ({ children }: { children: any }) => {
    return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    rowGap: '16px',
    columnGap: '36px',
    alignItems: 'start',
    width: '100%',
}))
