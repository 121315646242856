import { useAppSelector } from './storeHooks'
import { QueryContext } from '../types/types'

export const useGetCurrentQueryContext = (expanded_answer_id?: string): QueryContext | undefined => {
  const currentQueryContextList = useAppSelector(
    (state) => state.thread.currentQueryContextList,
  )
  if (!expanded_answer_id) return undefined
  return currentQueryContextList[expanded_answer_id]
}
