import LegalAPI, { url } from '../LegalAPI'
import { ThreadListFilterQuery } from '../../types/threads'
import { ThreadListResponseType, ThreadMessageType } from '../../types/types'


export const getThreadList = async (payload: ThreadListFilterQuery): Promise<ThreadListResponseType> => {
  return LegalAPI.post<ThreadListResponseType>(url`/threads/`, payload)
    .then(res => res.data)
}

export const getThreadById = async (thread_id: string): Promise<ThreadMessageType> => {
  return LegalAPI.get<ThreadMessageType>(url(`/thread_messages/${thread_id}/`))
    .then(res => res.data)
}
