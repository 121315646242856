import { memo, useCallback, useEffect, useState } from 'react'
import { useAppSelector } from '../hooks/storeHooks'
import { actionTypes } from '../types/message'
import { useDispatch } from 'react-redux'
import { SocketEvent } from '../reducers/socketReducer'
import { BACKEND_WS_URL } from '../config'
import { setToken } from '../actions/authActions'
import { getRefreshToken } from './utils/user'

export const WebSocketItem = memo(() => {
  const question = useAppSelector((state) => state.message.question)
  const dispatch = useDispatch()
  const isNewThread = useAppSelector((state) => state.thread.isNewThread)
  const currentThreadId = useAppSelector((state) => state.thread.currentThreadId)
  const token = useAppSelector((state) => state.auth.token)
  const socketEvent = useAppSelector((state) => state.socket.socketEvent)
  const [socket, setSocket] = useState<WebSocket | undefined>(undefined)

  const clearSocketEvent = () => {
    dispatch({
      type: actionTypes.SET_SOCKET_EVENT,
      payload: SocketEvent.none,
    })
  }

  const clearCurrentQuestion = () => {
    dispatch({
      type: actionTypes.SET_CURRENT_QUESTION,
      payload: '',
    })
  }

  const responseHandler = (data: any) => {
    switch (data.event) {
      case SocketEvent.threadCreate: {
        dispatch({
          type: actionTypes.UPDATE_NEW_THREAD_INFO,
          payload: data.content,
        })
        break
      }
      case SocketEvent.threadUpdate: {
        dispatch({
          type: actionTypes.UPDATE_THREAD_IN_LIST,
          payload: data.content,
        })
        break
      }
      case SocketEvent.tableCreate: {
        dispatch({
          type: actionTypes.SET_CURRENT_QUERY_CONTEXT,
          payload: data.content.context,
        })
        break
      }
      case SocketEvent.chatStreamStart: {
        dispatch({
          type: actionTypes.REMOVE_CHAT_RESPONSE_STATUS,
          payload: data.content,
        })
        dispatch({
          type: actionTypes.UPDATE_MESSAGE_METADATA,
          payload: data.content,
        })
        break
      }
      case SocketEvent.chatStream: {
        dispatch({
          type: actionTypes.UPDATE_BOT_ANSWER,
          payload: data.content,
        })
        break
      }
      case SocketEvent.chatResponseStatus: {
        dispatch({
          type: actionTypes.CHANGE_CHAT_RESPONSE_STATUS,
          payload: data.content,
        })
        break
      }
      case SocketEvent.chatStreamStop: {
        dispatch({
          type: actionTypes.SET_CURRENT_THREAD_LOADING_INPUT,
          payload: {
            thread_id: data.content.thread_id,
            value: false,
          },
        })
        break
      }
      case SocketEvent.stopGeneration: {
        dispatch({
          type: actionTypes.STOP_GENERATE_ANSWER,
          payload: data.content.id,
        })
        break
      }
      default:
        break
    }
  }

  const loadAnswerNewThread = useCallback(() => {
    if (!question) return
    const message = {
      event: SocketEvent.chatCreate,
      content: { text: question },
    }
    socket?.send(JSON.stringify(message))
    clearSocketEvent()
    clearCurrentQuestion()
  }, [socket, question])

  const loadAnswer = useCallback(() => {
    if (!question) return
    const message = {
      event: SocketEvent.chatContinue,
      content: { text: question, thread_id: currentThreadId },
    }
    socket?.send(JSON.stringify(message))
    clearSocketEvent()
    clearCurrentQuestion()
  }, [socket, currentThreadId, question])

  const stopGenerationInNewChat = useCallback(() => {
    const message = {
      event: SocketEvent.stopGeneration,
      content: {
        event: SocketEvent.chatCreate,
      },
    }
    socket?.send(JSON.stringify(message))
    clearSocketEvent()
    clearCurrentQuestion()
  }, [socket])

  const stopGenerationInExistChat = useCallback((id: string) => {
    const message = {
      event: SocketEvent.stopGeneration,
      content: {
        event: SocketEvent.chatContinue,
        id,
      },
    }
    socket?.send(JSON.stringify(message))
    clearSocketEvent()
    clearCurrentQuestion()
  }, [socket])

  const createRequest = useCallback(() => {
    if (!socket || socket.readyState !== WebSocket.OPEN) return null
    switch (socketEvent) {
      case SocketEvent.chatCreate:
        if (!isNewThread) {
          loadAnswer()
        } else {
          loadAnswerNewThread()
        }
        break
      case SocketEvent.stopGeneration:
        if (!isNewThread) {
          stopGenerationInExistChat(currentThreadId)
        } else {
          stopGenerationInNewChat()
        }
        break
      default:
        break
    }
  }, [socket, socketEvent, currentThreadId, isNewThread])

  const setRefreshToken = async (): Promise<string | undefined> => {
    try {
      const newToken = await getRefreshToken()
      if (newToken) {
        dispatch(setToken(newToken))
        return newToken
      }
    } catch (error) {
      console.error('Failed to refresh token', error)
    }
  }

  const getWsUrl = (access_token?: string) => {
    return `${BACKEND_WS_URL}/ws/questions?token=${access_token ? access_token : token}`
  }

  useEffect(() => {
    createRequest()
  }, [socketEvent, question])

  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
      }

      socket.onmessage = (event: MessageEvent) => {
        responseHandler(JSON.parse(event.data))
      }

      socket.onerror = (error: Event) => {
        console.error('WebSocket error', error)
      }

      socket.onclose = async (event: CloseEvent) => {
        setRefreshToken()
          .then((newToken) => {
            setSocket(new WebSocket(getWsUrl(newToken)))
            console.log('WebSocket connection closed')
          })
      }
    } else {
      setSocket(new WebSocket(getWsUrl()))
    }
  }, [socket])

  return null
})
