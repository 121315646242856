import React, { useState, ChangeEvent } from 'react'
import { Box, FilledInput, IconButton, InputAdornment } from '@mui/material'
import CoreIcon from './CoreIcon'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

interface CoreInputProps {
    icon?: string
    color?: string
    backgroundColor?: string
    width?: string
    fullwidth?: boolean
    value: string
    type: CoreInputType
    onChange: (value: string) => void
    placeholder?: string
    disabled?: boolean
    required?: boolean
}

export default function CoreInput({
    color,
    icon,
    backgroundColor,
    width,
    fullwidth,
    value,
    type,
    onChange,
    placeholder,
    disabled = false,
    required = false,
}: CoreInputProps) {
    const [showPassword, setShowPassword] = useState(false)

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        onChange(event.target.value)
    }

    const handleToggleShowPassword = () => {
        setShowPassword((prev) => !prev)
    }

    const showPasswordButton = (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleToggleShowPassword}
                edge="end"
            >
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    )

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: fullwidth ? '100%' : width,
                flex: 1,
            }}
        >
            {icon && <CoreIcon iconName={icon} color="white" />}
            <FilledInput
                value={value}
                onChange={handleOnChange}
                type={
                    type === 'password'
                        ? showPassword
                            ? 'text'
                            : 'password'
                        : type
                }
                endAdornment={type === 'password' && showPasswordButton}
                placeholder={placeholder}
                disableUnderline={true}
                disabled={disabled}
                required={required}
                sx={{
                    color: color,
                    backgroundColor: backgroundColor,
                    width: '100%',
                    height: '50px',
                    '& .MuiInputBase-input': {
                        color: color,
                        padding: '10px 14px',
                    },
                    '&.MuiFilledInput-root': {
                        borderRadius: '7px',
                        border: 'none',
                        '&:hover': {
                            backgroundColor: backgroundColor,
                        },
                        '&.Mui-focused': {
                            backgroundColor: backgroundColor,
                        },
                        '&.MuiFilledInput-underline:before': {
                            borderBottom: 'none',
                        },
                        '&.MuiFilledInput-underline:after': {
                            borderBottom: 'none',
                        },
                    },
                }}
            />
        </Box>
    )
}

export enum CoreInputType {
    Text = 'text',
    Password = 'password',
    Email = 'email',
}
