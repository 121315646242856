import { memo, useState } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import { styled } from '@mui/material/styles'
import CoreButton from '../../components/Core/CoreButton'
import theme from '../../theme/theme'
import { CustomSelectComponent } from './CustomSelectComponent'
import { ActList } from './ActList'
import { actionTypes } from '../../types/message'
import { useDispatch } from 'react-redux'

const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
]

export const PreferencesPage = memo(() => {
    const dispatch = useDispatch()
    const accountActs: string[] = useAppSelector(
        (state) => state.preference.accountActs
    )
    const companyActs: string[] = useAppSelector(
        (state) => state.preference.companyActs
    )
    const selectedColor: string = useAppSelector(
        (state) => state.preference.color
    )
    const [accountContext, setAccountContext] = useState<string>('')
    const [companyContext, setCompanyContext] = useState<string>('')

    const [colors, setColors] = useState<string[]>([
        '#FFE500',
        '#0092A4',
        '#6F7B89',
        '#000000',
        '#32343D',
    ])
    const addAccountActHandler = (act: string) => {
        dispatch({
            type: actionTypes.ADD_ACTS,
            payload: { type: 'account', act },
        })
    }
    const removeAccountActHandler = (act: string) => {
        dispatch({
            type: actionTypes.REMOVE_ACTS,
            payload: { type: 'account', act },
        })
    }

    const addCompanyActHandler = (act: string) => {
        dispatch({
            type: actionTypes.ADD_ACTS,
            payload: { type: 'company', act },
        })
    }
    const removeCompanyActHandler = (act: string) => {
        dispatch({
            type: actionTypes.REMOVE_ACTS,
            payload: { type: 'company', act },
        })
    }

    const savePreferencesHandler = () => {
        const data = {
            accountContext,
            companyContext,
            accountActs,
            companyActs,
            color: selectedColor,
        }
        setAccountContext('')
        setCompanyContext('')
        dispatch({
            type: actionTypes.CLEAR_ACTS,
        })
    }

    const addColorHandler = (color: string) => {
        dispatch({
            type: actionTypes.SET_PREFERENCE_COLOR,
            payload: color,
        })
    }

    const setAccountContextHandler = (context: string) => {
        dispatch({
            type: actionTypes.SET_PREFERENCE_ACCOUNT_CONTEXT,
            payload: context,
        })
    }

    const setCompanyContextHandler = (context: string) => {
        dispatch({
            type: actionTypes.SET_PREFERENCE_COMPANY_CONTEXT,
            payload: context,
        })
    }

    return (
        <Container>
            <Title>Choose preferences</Title>
            <ContentWrapper>
                <ContentList>
                    <ItemWrapper>
                        <SubTitle>Your account preferences</SubTitle>
                        <Label>Personal context</Label>
                        <TextArea
                            onChange={(e) =>
                                setAccountContextHandler(e.target.value)
                            }
                            value={accountContext}
                        />
                        <Label>Preferred legal acts</Label>
                        <CustomSelectComponent
                            selectedActs={accountActs}
                            addActHandler={addAccountActHandler}
                            options={options}
                            placeholder={'Select account act'}
                        />
                        <ActList
                            acts={accountActs}
                            removeActHandler={removeAccountActHandler}
                        />
                    </ItemWrapper>
                    <ItemWrapper>
                        <SubTitle>
                            Your company preferences (apply to all users)
                        </SubTitle>
                        <Label>Company context</Label>
                        <TextArea
                            onChange={(e) =>
                                setCompanyContextHandler(e.target.value)
                            }
                            value={companyContext}
                        />
                        <Label>Preferred legal acts</Label>
                        <CustomSelectComponent
                            selectedActs={companyActs}
                            addActHandler={addCompanyActHandler}
                            options={options}
                            placeholder={'Select company act'}
                        />
                        <ActList
                            acts={companyActs}
                            removeActHandler={removeCompanyActHandler}
                        />
                        <ExtrasContainer>
                            <Text>
                                Upload company logo for white label design
                                (horizontal transparent PNG)
                            </Text>
                            <CoreButton
                                text="SEE EXTRAS"
                                color={theme.palette.background.default}
                                width={200}
                                textColor={theme.palette.common.white}
                                onClick={() => {}}
                            />
                        </ExtrasContainer>
                        <ThemeContainer>
                            <Text>Adjust theme colours</Text>
                            <ThemeColoursContainer>
                                {colors.map((item) => (
                                    <ThemeColour
                                        onClick={() => addColorHandler(item)}
                                        style={{
                                            backgroundColor: item,
                                            cursor: 'pointer',
                                            scale:
                                                item === selectedColor
                                                    ? '1.3'
                                                    : '1',
                                        }}
                                    ></ThemeColour>
                                ))}
                            </ThemeColoursContainer>
                        </ThemeContainer>
                    </ItemWrapper>
                </ContentList>
                <ButtonRow>
                    <CoreButton
                        text="SKIP FOR NOW"
                        color={theme.palette.background.default}
                        width={200}
                        textColor={theme.palette.common.white}
                        onClick={() => {}}
                    />
                    <CoreButton
                        text="SAVE PREFERENCES"
                        color={theme.palette.secondary.light}
                        width={200}
                        textColor={theme.palette.common.white}
                        onClick={savePreferencesHandler}
                    />
                </ButtonRow>
            </ContentWrapper>
        </Container>
    )
})

const ButtonRow = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'flex-end',
    height: '70px',
}))

const Label = styled('span')(({ theme }) => ({
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '22px',
    color: theme.palette.common.white,
    marginBottom: '10px',
}))

const TextArea = styled('textarea')(({ theme }) => ({
    width: '100%',
    height: '186px',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '7px',
    backgroundColor: theme.palette.common.white,
    resize: 'none',
    marginBottom: '30px',
    fontFamily: 'Inter ',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '22px',
}))

const SubTitle = styled('span')(({ theme }) => ({
    fontSize: '26px',
    fontWeight: 400,
    lineHeight: '32px',
    color: theme.palette.common.white,
    marginBottom: '30px',
}))

const ItemWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}))

const ContentWrapper = styled('div')(({ theme }) => ({
    padding: '45px 93px 27px 93px',
    backgroundColor: '#32343D',
}))

const ContentList = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '154px',
}))

const Container = styled('div')(({ theme }) => ({
    backgroundColor: '#6F7B89',
    padding: '88px 85px',
    height: '100hv',
}))

const Title = styled('h1')(({ theme }) => ({
    fontSize: '47px',
    fontWeight: 400,
    lineHeight: '57px',
    textAlign: 'center',
    color: theme.palette.common.white,
    margin: '0 0 30px 0',
}))

const ExtrasContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '20px 0',
}))

const Text = styled('span')(({ theme }) => ({
    maxWidth: '35%',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '22px',
    color: theme.palette.common.white,
}))

const ThemeColoursContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '17px',
    boxSizing: 'border-box',
    borderRadius: '12px',
    padding: '17px',
    backgroundColor: theme.palette.common.white,
}))

const ThemeColour = styled('div')(({ theme }) => ({
    borderRadius: '12px',
    padding: '20px',
    backgroundColor: 'yellow',
}))

const ThemeContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 0',
}))
