import React from 'react'
import { styled } from '@mui/material/styles'
import { Grade } from '../../../types/grade'
import { Typography } from '@mui/material'
import CoreIcon from '../../Core/CoreIcon'
import IconButton from '@mui/material/IconButton'
import { HeartIcon } from '../../../icons/HeartIcon'
import { MessagePublicType } from '../../../types/types'

interface FeedbackBlockProps {
  message: MessagePublicType
  onGradeSelected: (grade: Grade) => void
  onGetContext: () => void
  onAddToFavorites: () => void
  isFavorite: boolean
}

interface FeedbackButtonProps {
  active?: boolean
  grade: string
  rated: boolean
}

const FeedbackBlock: React.FC<FeedbackBlockProps> = ({
  onGradeSelected,
  message,
  onGetContext,
  onAddToFavorites,
  isFavorite,
}) => {
  const handleGradeClick = (grade: Grade) => {
    onGradeSelected(grade)
  }

  //const rated = message.rating != undefined

  return (
    <FeedbackContainer>
      <ThumbsContainer>
        <IconButton onClick={onAddToFavorites}>
          <HeartIcon color={isFavorite ? 'white' : '#FFFFFF7F'} />
        </IconButton>
        {message.is_legal && (
          <IconButton onClick={onGetContext}>
            <CoreIcon iconName="context-icon" />
          </IconButton>
        )}
      </ThumbsContainer>
      {/*{!rated && (*/}
      {/*    <>*/}
      {/*        <Typography*/}
      {/*            typography="caption"*/}
      {/*            fontSize={14}*/}
      {/*            color="rgba(255,255,255,0.3)"*/}
      {/*        >*/}
      {/*            Keep improving my answers by giving me feedback*/}
      {/*        </Typography>*/}
      {/*    </>*/}
      {/*)}*/}
      <GradeContainer>
        {Object.entries(Grade).map(([grade, value]) => {
          if (isNaN(Number(grade))) {
            return (
              <FeedbackButton
                key={grade}
                onClick={() => handleGradeClick(value as Grade)}
                grade={grade}
                rated={false} //{message.rating != undefined}
                active={false} //{message.rating == value}
              >
                {grade}
              </FeedbackButton>
            )
          }
          return null
        })}
      </GradeContainer>
    </FeedbackContainer>
  )
}

export default FeedbackBlock

const FeedbackContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '25px',
  alignContent: 'flex-end',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
}))

const ThumbsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

const GradeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const FeedbackButton = styled('div')<FeedbackButtonProps>(
  ({ theme, active, grade, rated }) => ({
    width: grade.length * 10 + 25 + 'px',
    height: '30px',
    fontSize: '22px',
    fontWeight: 700,
    color: 'rgba(255,255,255,0.5)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
    '&:hover': {
      transform: 'scale(1.2)',
      color: 'rgba(255,255,255,1)',
    },
    ...(rated && {
      color: 'rgba(255,255,255,0.1)',
    }),
    ...(active && {
      transform: 'scale(1.2)',
      color: 'rgba(255,255,255,1)',
    }),
  }),
)
