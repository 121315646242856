import { memo } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import CoreIcon from '../../Core/CoreIcon'
import { useDispatch } from 'react-redux'
import { actionTypes } from '../../../types/message'

const NewThreadBlock = memo(() => {
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch({
      type: actionTypes.ADD_NEW_THREAD,
    })
  }

  return (
    <NewThreadBlockContainer
      endIcon={<CoreIcon iconName="new-icon" color="white" size={32} />}
      onClick={handleOnClick}
    >
      New thread
    </NewThreadBlockContainer>
  )
})

export default NewThreadBlock

const NewThreadBlockContainer = styled(Button)(({ theme }) => ({
  margin: '0 10px 0 10px',
  height: '50px',
  display: 'flex',
  alignContent: 'space-between',
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.text.primary,
  fontSize: '22px',
  fontWeight: 500,
  boxShadow:
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  textTransform: 'none',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}))
