import { Action } from '@reduxjs/toolkit'
import { actionTypes } from '../types/message'
import { LOGOUT_USER } from '../actions/types'

interface InitialState {
    accountContext: string
    companyContext: string
    accountActs: string[]
    companyActs: string[]
    color: string
}

const INITIAL_STATE: InitialState = {
    accountContext: '',
    companyContext: '',
    accountActs: [],
    companyActs: [],
    color: '',
}

interface ActionType extends Action {
    payload: any
}

export const preferenceReducer = (
    state = INITIAL_STATE,
    action: ActionType
) => {
    const newState = { ...state }
    switch (action.type) {
        case actionTypes.ADD_ACTS:
            if (
                action.payload.type === 'account' &&
                !newState.accountActs.includes(action.payload.act)
            ) {
                newState.accountActs = [
                    ...newState.accountActs,
                    action.payload.act,
                ]
            }
            if (
                action.payload.type === 'company' &&
                !newState.companyActs.includes(action.payload.act)
            ) {
                newState.companyActs = [
                    ...newState.companyActs,
                    action.payload.act,
                ]
            }
            break
        case actionTypes.REMOVE_ACTS:
            if (action.payload.type === 'account') {
                newState.accountActs = newState.accountActs.filter(
                    (item) => item !== action.payload.act
                )
            }
            if (action.payload.type === 'company') {
                newState.companyActs = newState.companyActs.filter(
                    (item) => item !== action.payload.act
                )
            }
            break
        case actionTypes.CLEAR_ACTS:
            newState.accountActs = []
            newState.companyActs = []
            break
        case actionTypes.SET_PREFERENCE_ACCOUNT_CONTEXT:
            newState.accountContext = action.payload
            break
        case actionTypes.SET_PREFERENCE_COMPANY_CONTEXT:
            newState.companyContext = action.payload
            break
        case actionTypes.SET_PREFERENCE_COLOR:
            newState.color = action.payload
            break
        case LOGOUT_USER:
            return INITIAL_STATE
        default:
            break
    }
    return newState
}
